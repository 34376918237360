import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { } from '../../../store/actions/admin'

class Help extends Component {

    componentDidMount = async () => {
        try {
            // await this.props.getUsers()
        } catch (e) {
            console.log(e.message)
        }
    }


    render() {
        return (
            <AUX>
                <div className="page-content-wrapper">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card m-b-20">
                                    <div className="card-body">
                                        <h4 className="mt-0 header-title">Frequently Asked Questions</h4>
                                        <div className="m-t-30">
                                            <div id="accordion">
                                                <div className="card">
                                                    <div className="card-header bg-white border-bottom-0 p-3" id="headingOne">
                                                        <h5 className="mb-0 mt-0 font-16 font-light">
                                                            <a href="#" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                                                                aria-controls="collapseOne" className="text-dark">
                                                                Q.1 How to create an Annoucement?
                                                    </a>
                                                        </h5>
                                                    </div>

                                                    <div id="collapseOne" className="collapse show"
                                                        aria-labelledby="headingOne" data-parent="#accordion">
                                                        <div className="card-body text-muted">
                                                            <p>
                                                                1. To create an annoucement you must select the 'Annoucement' page
                                                                from the left sidebar.
                                                            </p>

                                                            <p>
                                                                2. In the Annoucement page, you will see a blue button on the top right
                                                                called 'Create Annoucement'. Click this button.
                                                            </p>
                                                            <p>
                                                                3. The "Create Annoucement" form will appear asking you to enter all the
                                                                details of your announcement.
                                                            </p>
                                                            <p>
                                                                4. Once you are done completing all the details, click the green 'Done'
                                                                button at the bottom of the form.
                                                            </p>
                                                            <p>
                                                                5. You should see you annoucement appear as the first item in the annoucement
                                                                page.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header bg-white border-bottom-0 p-3" id="headingTwo">
                                                        <h5 className="mb-0 mt-0 font-16 font-light">
                                                            <a href="#" className="text-dark collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                Q.2 How to view a New Incidence?
                                                    </a>
                                                        </h5>
                                                    </div>
                                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                                        <div className="card-body text-muted">
                                                            <p>
                                                                1. To view an annoucement you must select the 'Annoucement' page
                                                                from the left sidebar.
                                                            </p>

                                                            <p>
                                                                2. In the Annoucement page, you will see a blue button on the top right
                                                                called 'Create Annoucement'. Click this button.
                                                            </p>
                                                            <p>
                                                                3. The "Create Annoucement" form will appear asking you to enter all the
                                                                details of your announcement.
                                                            </p>
                                                            <p>
                                                                4. Once you are done completing all the details, click the green 'Done'
                                                                button at the bottom of the form.
                                                            </p>
                                                            <p>
                                                                5. You should see you annoucement appear as the first item in the annoucement
                                                                page.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header bg-white border-bottom-0 p-3" id="headingThree">
                                                        <h5 className="mb-0 mt-0 font-16 font-light">
                                                            <a href="#" className="text-dark collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                Q.3 How to change company logo?
                                                    </a>
                                                        </h5>
                                                    </div>
                                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                                        <div className="card-body text-muted">
                                                            <p>
                                                                1. To create an annoucement you must select the 'Annoucement' page
                                                                from the left sidebar.
                                                            </p>

                                                            <p>
                                                                2. In the Annoucement page, you will see a blue button on the top right
                                                                called 'Create Annoucement'. Click this button.
                                                            </p>
                                                            <p>
                                                                3. The "Create Annoucement" form will appear asking you to enter all the
                                                                details of your announcement.
                                                            </p>
                                                            <p>
                                                                4. Once you are done completing all the details, click the green 'Done'
                                                                button at the bottom of the form.
                                                            </p>
                                                            <p>
                                                                5. You should see you annoucement appear as the first item in the annoucement
                                                                page.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header bg-white border-bottom-0 p-3" id="headingFour">
                                                        <h5 className="mb-0 mt-0 font-16 font-light">
                                                            <a href="#" className="text-dark collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                Q.4 How to generate a report?
                                                    </a>
                                                        </h5>
                                                    </div>
                                                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                                        <div className="card-body text-muted">
                                                            <p>
                                                                1. To create an annoucement you must select the 'Annoucement' page
                                                                from the left sidebar.
                                                            </p>

                                                            <p>
                                                                2. In the Annoucement page, you will see a blue button on the top right
                                                                called 'Create Annoucement'. Click this button.
                                                            </p>
                                                            <p>
                                                                3. The "Create Annoucement" form will appear asking you to enter all the
                                                                details of your announcement.
                                                            </p>
                                                            <p>
                                                                4. Once you are done completing all the details, click the green 'Done'
                                                                button at the bottom of the form.
                                                            </p>
                                                            <p>
                                                                5. You should see you annoucement appear as the first item in the annoucement
                                                                page.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header bg-white border-bottom-0 p-3" role="tab" id="headingFive">
                                                        <h5 className="mb-0 mt-0 font-16 font-light">
                                                            <a href="#" className="text-dark collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                                Q.5 How to see the latest activities?
                                                    </a>
                                                        </h5>
                                                    </div>
                                                    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                                        <div className="card-body text-muted">
                                                            <p>
                                                                1. To create an annoucement you must select the 'Annoucement' page
                                                                from the left sidebar.
                                                            </p>

                                                            <p>
                                                                2. In the Annoucement page, you will see a blue button on the top right
                                                                called 'Create Annoucement'. Click this button.
                                                            </p>
                                                            <p>
                                                                3. The "Create Annoucement" form will appear asking you to enter all the
                                                                details of your announcement.
                                                            </p>
                                                            <p>
                                                                4. Once you are done completing all the details, click the green 'Done'
                                                                button at the bottom of the form.
                                                            </p>
                                                            <p>
                                                                5. You should see you annoucement appear as the first item in the annoucement
                                                                page.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header bg-white border-bottom-0 p-3" role="tab" id="headingSix">
                                                        <h5 className="mb-0 mt-0 font-16 font-light">
                                                            <a href="#" className="text-dark collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                                Q.6 View user audience?
                                                    </a>
                                                        </h5>
                                                    </div>
                                                    <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                                                        <div className="card-body text-muted">
                                                            <p>
                                                                1. To create an annoucement you must select the 'Annoucement' page
                                                                from the left sidebar.
                                                            </p>

                                                            <p>
                                                                2. In the Annoucement page, you will see a blue button on the top right
                                                                called 'Create Annoucement'. Click this button.
                                                            </p>
                                                            <p>
                                                                3. The "Create Annoucement" form will appear asking you to enter all the
                                                                details of your announcement.
                                                            </p>
                                                            <p>
                                                                4. Once you are done completing all the details, click the green 'Done'
                                                                button at the bottom of the form.
                                                            </p>
                                                            <p>
                                                                5. You should see you annoucement appear as the first item in the annoucement
                                                                page.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </AUX>
        );
    }
}

const mapStatetoProps = state => {
    return {
        state: state.admin
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch)
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Help));