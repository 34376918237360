import { firestore } from '../../config/firebase'
import firebase from 'firebase'
import { orderBy } from 'lodash'
import { getAlerts } from './alert'
import { getUsers } from './user'
import { getCompany } from './company'
import { getResidences } from './residence'
import { getAnnoucements } from './annoucement'
import { getActivity } from './activity'
import uuid from 'uuid'
const PUSH_ENDPOINT = 'https://fcm.googleapis.com/fcm/send'

export const sync = () => {
    return async (dispatch, getState) => {
        try {
            // await dispatch(getCompany())
            await dispatch(getUsers())
            await dispatch(getAlerts())
            await dispatch(getResidences())
            await dispatch(getAnnoucements())
            await dispatch(getActivity())
        } catch (e) {
            console.log(e.message)
        }
    }
}

export const sendNotification = (uid, title, message, data) => {
    return async (dispatch, getState) => {
        try {
            const userQuery = await firestore.collection('user').doc(uid).get()
            let user = userQuery.data()

            console.log(user)

            if (user && user.token) {
                let r = await fetch(PUSH_ENDPOINT, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'key=AAAA73NZmJE:APA91bF7S_KgrcutApHtkastDey3IUEUcb-Z2Zl9NB_ooeTuOfsS0l1rLv7ezzBErcAmZhjfYYEsFLboBd3LlsHnj113urIKtS4intopqzEBl5Rwd4BTbbKa1bio1-aQDZZ7pe09X4IA'
                    },
                    body: JSON.stringify({
                        to: user.token,
                        collapse_key: 'type_a',
                        notification: {
                            title: title,
                            body: message,
                            tag: uuid.v4(),
                            "vibrate": 1,
                            "sound": 1,
                            "show_in_foreground": true,
                            "priority": "high",
                            "content_available": true,
                        },
                        data: data
                    })
                })
                console.log(r)
            }
        } catch (e) {
            console.log(e.message)
        }
    }
}

export const notifyAll = (title, message, data) => {
    return async (dispatch, getState) => {
        try {
            const { users } = getState().user
            users.forEach(async (user) => {
                await dispatch(sendNotification(user.uid, title, message, data))
            })
        } catch (e) {
            console.error(e)
        }
    }
}

export const notifyAllResidents = (residenceUid, title, message, data) => {
    return async (dispatch, getState) => {
        try {
            const { users } = getState().user
            users.filter(u => u.residenceUid === residenceUid).forEach(async (user) => {
                console.log(user)
                await dispatch(sendNotification(user.uid, title, message, data))
            })
        } catch (e) {
            console.error(e)
        }
    }
}

export const sendToGroup = (group, title, message, data) => {
    return async (dispatch, getState) => {
        try {
            group.forEach(async (item) => {
                await dispatch(sendNotification(item, title, message, data))
            })

        } catch (e) {
            console.log(e.error())
        }
    }
}






