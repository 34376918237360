import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { getActivity } from '../../../store/actions/activity'
import moment from 'moment'
import { getAlerts, editAlert, deleteAlert } from '../../../store/actions/alert'
import { Button, FormText } from 'reactstrap';
import { storage } from 'firebase'
import uuid from 'uuid'

class Activities extends Component {

    state = {
        alert: {
            category: '',
            description: '',
            alertPhotos: { localUrl: [], remoteUrl: [] },
            document: { localUrl: [], remoteUrl: [], name: [] },
            location: { gps: {}, address: '' },
            updates: []
        },
        comment: '',
        errorMessage: '',
        isEditMode: false,
        itemCount: 10,
        category: 'All'
    }

    dataValidation = () => {
        if (!this.state.alert.category) {
            this.setState({ errorMessage: 'Missing Category' })
            return false
        }
        else if (!this.state.alert.description) {
            this.setState({ errorMessage: 'Missing Description' })
            return false
        }
        else {
            this.setState({ errorMessage: '' })
            return true
        }
    }

    editAlert = async (e) => {
        try {
            e.preventDefault()

            if (!this.dataValidation()) {
                return
            }

            let newUpdates = this.state.alert.updates
            newUpdates.push({
                id: uuid.v4(),
                name: this.props.company.name,
                photo: this.props.company.photo,
                date: new Date().getTime(),
                comment: this.state.comment || 'Status changed to ' + this.state.alert.status,
                status: this.state.alert.status,
                event: this.state.comment ? 'Comment-Update' : 'Status-Update'
            })

            this.setState({ isEditMode: false })

            if (await this.props.editAlert(this.state.alert)) {

            } else {

            }
        } catch (e) {
            console.log(e.message)
        }
    }

    deleteAlert = async (e) => {
        try {
            // e.preventDefault()

            if (await this.props.deleteAlert(this.state.alert)) {
                this.setState({})
            } else {

            }
        } catch (e) {
            console.log(e.message)
        }
    }

    editMode = async (e) => {
        try {
            e.preventDefault()
            this.setState({ isEditMode: true })
        } catch (e) {
            console.log(e.message)
        }
    }

    handleDocuments = e => {

        if (e.target.files[0]) {
            const doc = e.target.files[0];
            this.uploadDocument(doc)
        }
    }

    uploadDocument = async (doc) => {
        try {

            const uploadTask = storage().ref(`documents/${doc.name}`).put(doc)

            uploadTask.on(
                "state_changed",
                snapshot => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    this.setState({ progress });
                },
                error => {
                    console.log(error);
                },
                async () => {
                    let url = await storage().ref("documents").child(doc.name).getDownloadURL()
                    let updatedDocument = this.state.alert.document
                    updatedDocument.remoteUrl.push(url)
                    updatedDocument.name.push(doc.name)
                    this.setState({ alert: { ...this.state.alert, document: updatedDocument } });
                    console.log(this.state.alert.document)
                }
            );
        } catch (e) {
            console.log(e)
        }
    }

    handleImages = e => {

        if (e.target.files[0]) {
            const image = e.target.files[0];
            this.uploadImage(image)
        }
    }

    uploadImage = async (image) => {
        try {

            const uploadTask = storage().ref(`images/${image.name}`).put(image);
            uploadTask.on(
                "state_changed",
                snapshot => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    this.setState({ progress });
                },
                error => {
                    console.log(error);
                },
                async () => {
                    let url = await storage().ref("images").child(image.name).getDownloadURL()
                    let updatedalertPhotos = this.state.alert.alertPhotos
                    updatedalertPhotos.remoteUrl.push(url)
                    this.setState({ alert: { ...this.state.alert, alertPhotos: updatedalertPhotos } });
                    console.log(this.state.alert.alertPhotos)
                }
            );
        } catch (e) {
            console.log(e)
        }
    }


    componentDidMount = async () => {
        try {
            await this.props.getActivity()
        } catch (e) {
            console.log(e.message)
        }
    }

    render() {
        const data = this.state.category === 'All' ? this.props.activities.filter(a => a.activity !== 'NEW-ANNOUCEMENT').slice(0, this.state.itemCount) : this.props.activities.filter(a => a.activity !== 'NEW-ANNOUCEMENT' && a.activity === this.state.category).slice(0, this.state.itemCount)
        return (
            <AUX>
                <div className="page-content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3">
                                <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-2 col-form-label">Search</label>
                                    <div className="col-sm-8">
                                        <input className="form-control" type="text" placeholder="Activity Query" id="example-text-input" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Category</label>
                                    <div className="col-sm-7">
                                        <select onChange={(event) => this.setState({ category: event.target.value })} value={this.state.category} className="form-control select2">
                                            <option>All</option>
                                            <option>NEW-ALERT</option>
                                            <option>UPDATE-ALERT</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Show Items</label>
                                    <div className="col-sm-7">
                                        <select onChange={(event) => this.setState({ itemCount: event.target.value })} value={this.state.itemCount} className="form-control select2">
                                            <option>10</option>
                                            <option>25</option>
                                            <option>50</option>
                                            <option>100</option>
                                            <option>200</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12">
                                <div className="card m-b-20">
                                    <div className="card-body">
                                        <h4 className="mt-0 m-b-30 header-title">The latest actions occuring from residents</h4>
                                        <div className="table-responsive">
                                            <table className="table table-vertical mb-0">
                                                <tbody>
                                                    {
                                                        data.map(item => (
                                                            <tr>
                                                                <td>
                                                                    <img src={item.photo} alt="user-image" className="thumb-sm rounded-circle mr-2" />
                                                                    {item.actorName}
                                                                </td>
                                                                <td>
                                                                    <i className="mdi mdi-checkbox-blank-circle text-success"></i>{item.activity === 'NEW-ALERT' ? 'Has logged a new incidence' : 'Has updated a incidence.'}
                                                                </td>
                                                                <td>
                                                                    {moment(item.date).format('llll')}
                                                                </td>
                                                                <td>
                                                                    <button onClick={() => this.setState({ alert: this.props.alert.alerts.find(a => a.id === item.alertId) })} data-toggle="modal" data-target="#viewAlert" type="button" className="btn btn-success btn-sm waves-effect">View</button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div id="viewAlert" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                        <div style={{ margin: 'auto', paddingTop: 30, paddingBottom: 30, width: '80%' }} className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0" id="myModalLabel">View Alert</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                </div>
                                <div className="modal-body">
                                    {
                                        this.state.errorMessage ?
                                            <p style={{ color: 'red' }} className="text-muted m-b-30 font-14 alert-danger">{this.state.errorMessage}</p> : null
                                    }

                                    <div className="card-body">
                                        <form>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label className="control-label">Status</label>
                                                        {
                                                            this.state.isEditMode ?
                                                                <select onChange={(event) => this.setState({ alert: { ...this.state.alert, status: event.target.value } })} value={this.state.alert.status} className="form-control select2">
                                                                    <option value="Open">Open</option>
                                                                    <option value="Pending">Pending</option>
                                                                    <option value="Closed">Closed</option>
                                                                </select> :
                                                                <p className="text-muted m-b-30 font-14">{this.state.alert.status}</p>
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="control-label">Category</label>
                                                        {
                                                            this.state.isEditMode ?
                                                                <select onChange={(event) => this.setState({ alert: { ...this.state.alert, category: event.target.value } })} value={this.state.alert.category} className="form-control select2">
                                                                    <option>Select</option>
                                                                    <option value="General">General</option>
                                                                    <option value="Security">Security</option>
                                                                    <option value="Weather">Administrative</option>
                                                                    <option value="Blackout">Promotion</option>
                                                                </select> :
                                                                <p className="text-muted m-b-30 font-14">{this.state.alert.category}</p>
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="metadescription">Location</label>
                                                        <p className="text-muted m-b-30 font-14">{this.state.alert.location.address}</p>
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="metadescription">Description</label>
                                                        <p className="text-muted m-b-30 font-14">{this.state.alert.description}</p>
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="metadescription">Images</label>
                                                        <div className='row'>
                                                            {
                                                                this.state.alert.alertPhotos.remoteUrl.length > 0 ?
                                                                    this.state.alert.alertPhotos.remoteUrl.map(url => (
                                                                        <div className='col-sm-4'>
                                                                            <img style={{ height: 100, width: 100, margin: 8 }} src={url} alt="product img" className="img-fluid" />
                                                                        </div>
                                                                    ))
                                                                    : null
                                                            }
                                                        </div>
                                                        {
                                                            this.state.isEditMode ?
                                                                <div className="custom-file">
                                                                    <input
                                                                        onChange={this.handleImages}
                                                                        type="file"
                                                                        className="custom-file-input"
                                                                        accept="image/x-png,image/gif,image/jpeg"
                                                                        id="inputGroupFile01"
                                                                        aria-describedby="inputGroupFileAddon01" />
                                                                    <label style={{ width: '100%', textAlign: 'center' }} color="secondary" className="btn btn-secondary" htmlFor="inputGroupFile01">
                                                                        Upload Image
                                                         </label>
                                                                </div> : null
                                                        }

                                                    </div>
                                                    <div className="form-group">
                                                        <label for="metadescription">Documents</label>
                                                        <div className='row'>
                                                            {
                                                                this.state.alert.document.remoteUrl.length > 0 ?
                                                                    this.state.alert.document.remoteUrl.map((url, index) => (
                                                                        <div style={{ textAlign: 'center' }} className='col-sm-4 text-center'>
                                                                            <img style={{ height: 100, width: 100, margin: 8, textAlign: 'center' }} src={"assets/images/doc.png"} alt="product img" className="img-fluid" />
                                                                            <FormText style={{ textAlign: 'center' }}>{this.state.alert.document.name[index]}</FormText>
                                                                        </div>
                                                                    ))
                                                                    : null
                                                            }
                                                        </div>
                                                        {
                                                            this.state.isEditMode ?
                                                                <div className="custom-file">
                                                                    <input
                                                                        onChange={this.handleDocuments}
                                                                        accept=".pdf"
                                                                        type="file"
                                                                        className="custom-file-input"
                                                                        id="inputGroupFile02"
                                                                        aria-describedby="inputGroupFileAddon01" />
                                                                    <label style={{ width: '100%', textAlign: 'center' }} color="secondary" className="btn btn-secondary" htmlFor="inputGroupFile02">
                                                                        Upload Documents
                                                        </label>
                                                                </div> : null
                                                        }

                                                    </div>
                                                    {
                                                        this.state.isEditMode ?
                                                            <div className="form-group">
                                                                <label for="metacomment">Comment</label>
                                                                <textarea onChange={(event) => this.setState({ comment: event.target.value })} value={this.state.comment} className="form-control" id="comment" rows="5"></textarea>
                                                            </div> : null
                                                    }
                                                    {
                                                        this.state.alert.updates.length > 0 ?
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <label for="metacomment">Updates</label>
                                                                    {this.state.alert.updates.map(item => (
                                                                        <>
                                                                            {
                                                                                this.props.admin.firstname + ' ' + this.props.admin.lastname === item.name ?

                                                                                    <div style={{ margin: 15, alignItems: 'flex-start' }} className='row'>
                                                                                        <img style={{ width: 75, height: 75, justifyContent: 'center' }} src={item.photo} className="img-fluid rounded-circle" alt="work-thumbnail" />
                                                                                        <div className="detail col-sm-9">
                                                                                            <FormText style={{ fontSize: 18, fontWeight: 'bold' }} className="font-16">{item.name}</FormText>
                                                                                            <FormText style={{ fontSize: 14 }} className="font-13">{item.comment}</FormText>
                                                                                            <FormText style={{ fontSize: 12 }} className="font-13">{moment(item.date).format('llll')}</FormText>
                                                                                        </div>
                                                                                    </div> :
                                                                                    <div style={{ margin: 15, alignItems: 'flex-end' }} className='row'>
                                                                                        <div style={{ alignSelf: 'flex-end' }} className="detail col-sm-9">
                                                                                            <FormText style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'end' }} className="font-16">{item.name}</FormText>
                                                                                            <FormText style={{ fontSize: 14, textAlign: 'end' }} className="font-13">{item.comment}</FormText>
                                                                                            <FormText style={{ fontSize: 12, textAlign: 'end' }} className="font-13">{moment(item.date).format('llll')}</FormText>
                                                                                        </div>
                                                                                        <img style={{ width: 75, height: 75, justifyContent: 'center' }} src={item.photo} className="img-fluid rounded-circle" alt="work-thumbnail" />

                                                                                    </div>
                                                                            }
                                                                        </>
                                                                    ))}
                                                                    {/* </div> */}
                                                                </div>
                                                            </div> : null
                                                    }
                                                    <div >
                                                        {
                                                            this.state.isEditMode ?
                                                                <div style={{ margin: 15 }}>
                                                                    <Button onClick={(e) => this.editAlert(e)} type="success" color='primary' block>Done</Button>
                                                                </div> :
                                                                <div style={{ margin: 15 }}>
                                                                    <Button onClick={(e) => this.editMode(e)} color='primary' block>Edit</Button>
                                                                    <Button data-toggle="modal" data-target="#viewAnnoucment" onClick={() => this.deleteAlert()} color='danger' block>Delete</Button>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AUX>
        );
    }
}

const mapStatetoProps = state => {
    return {
        activities: state.activity.activities,
        alert: state.alert,
        admin: state.admin,
        company: state.company
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getActivity, getAlerts, editAlert, deleteAlert }, dispatch)
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Activities));