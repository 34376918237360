import { firestore } from '../../config/firebase'
import { orderBy } from 'lodash'
import uuid from 'uuid'

export const getUsers = () => {
    return async (dispatch, getState) => {
        try {
            const { admin } = getState()
            let resolvedItems = []


            const query = await firestore.collection('user').where('companyUid', '==', admin.company.uid).get()
            query.forEach(async (item) => {
                resolvedItems.push(item.data())
            })

            console.log(resolvedItems)

            console.log(resolvedItems)
            dispatch({ type: 'UPDATE_USERS', payload: resolvedItems })
        } catch (e) {
            console.error(e)
        }
    }
}

export const createUser = (formData) => {
    return async (dispatch, getState) => {
        try {
            let { user, admin, company, residence, activity } = getState()

            let id = uuid.v4()

            const newUser = {
                uid: id,
                companyUid: company.uid,
                accessCode: id.slice(0, 5),
                residenceUid: residence.residences.find(r => r.name === formData.residence).id,
                date: new Date().getTime(),
                createAt: new Date().getTime(),
                location: { gps: { longitude: 0, latitude: 0 }, address: '' },
                role: '',
                photo: { localUrl: '', remoteUrl: '' },
                kinName: '',
                kinNumber: '',
                kinRelationship: '',
                medAid: '',
                medAidNumber: '',
                medConditions: '',
                medBloodtype: '',
                isVerified: false,
                token: '',
                isAuthenticated: false,
                residence: '',
                alerts: [],
                logs: [],
                ...formData,
            }

            user.users.push(newUser)
            dispatch({ type: 'UPDATE_USERS', payload: orderBy(user.users, 'date', 'desc') })

            await firestore.collection('user').doc(newUser.uid).set(newUser)

            let newActivity = {
                id: uuid.v4(),
                userId: newUser.uid,
                actorUid: admin.uid,
                actorPhoto: company.photo,
                actorName: admin.firstname + " " + admin.lastname,
                ownerUid: newUser.uid,
                ownerName: newUser.firstname + " " + newUser.lastname,
                ownerPhoto: newUser.photo.remoteUrl,
                photo: newUser.photo.remoteUrl,
                residenceUid: newUser.residenceUid,
                companyUid: company.uid,
                date: new Date().getTime(),
                activity: 'NEW-USER',
            }

            activity.activities.push(newActivity)
            dispatch({ type: 'UPDATE_ACTIVITIES', payload: orderBy(activity.activities, 'date', 'desc') })
            await firestore.collection('activities').doc(newActivity.id).set(newActivity)

            // dispatch(notifyAll('A new Annoucement has been added.', newAnnoucement.title, { annoucementId: newAnnoucement.id }))

            return true
        } catch (e) {
            console.log(e.message)
            return false
        }
    }
}

export const editUser = (formData) => {
    return async (dispatch, getState) => {
        try {
            let { user } = getState()

            const updateUser = {
                updatedDate: new Date().getTime(),
                ...formData,
            }

            let newUsers = user.users.map(item => {
                if (item.uid === updateUser.uid) {
                    item = updateUser
                } return item
            })


            if (newUsers.length > 0) {
                dispatch({ type: 'UPDATE_USERS', payload: orderBy(newUsers, 'date', 'desc') })
            }

            await firestore.collection('user').doc(updateUser.uid).update(updateUser)
            return true
        } catch (e) {
            console.log(e.message)
            return false
        }
    }
}

export const deleteUser = (uid) => {
    return async (dispatch, getState) => {
        try {
            let { user } = getState()

            user.users.splice(uid, 1)

            dispatch({ type: 'UPDATE_USERS', payload: user.users })

            await firestore.collection('user').doc(uid).delete()

            return true
        } catch (e) {
            console.log(e.message)
            return false
        }
    }
}
