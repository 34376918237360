import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import C3Chart from 'react-c3js';
import 'c3/c3.css';
import { connect } from 'react-redux';

const colors = {
  pattern: ['#4ac18e', '#6d60b0', '#5468da', '#9368da', '#1268da']
};

const data = {
  type: 'donut',
  columns: [
    ['Open', 10],
    ['Reviewed', 20],
    ['Pending', 15],
    ['Resolved', 15],
    ['Closed', 40],
  ],

  onclick: function (d, i) { console.log("onclick", d, i); },
  onmouseover: function (d, i) { console.log("onmouseover", d, i); },
  onmouseout: function (d, i) { console.log("onmouseout", d, i); }
};

class AudienceChart extends Component {

  render() {
    const general = 'General: ' + this.props.annoucement.viewStats.generalViews
    const security = 'Security: ' + this.props.annoucement.viewStats.securityViews
    const administrative = 'Administrative: ' + this.props.annoucement.viewStats.administrativeViews
    const promotion = 'Promotion: ' + this.props.annoucement.viewStats.promotionViews
    const flash = 'Flash: ' + this.props.annoucement.viewStats.flashViews
    return (
      <AUX>
        <C3Chart data={{
          type: 'pie',
          columns: [
            [general, this.props.annoucement.viewStats.generalViews],
            [security, this.props.annoucement.viewStats.securityViews],
            [administrative, this.props.annoucement.viewStats.administrativeViews],
            [promotion, this.props.annoucement.viewStats.promotionViews],
            [flash, this.props.annoucement.viewStats.flashViews],
          ],

          onclick: function (d, i) { console.log("onclick", d, i); },
          onmouseover: function (d, i) { console.log("onmouseover", d, i); },
          onmouseout: function (d, i) { console.log("onmouseout", d, i); }
        }}
          size={{
            height: 500,
            width: 500
          }}
          color={colors} />
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {
    annoucement: state.annoucement
  };
}

export default connect(mapStatetoProps)(AudienceChart);   