import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { } from '../../../store/actions/admin'
import { sync } from '../../../store/actions/index'
import { getAlerts, editAlert, deleteAlert } from '../../../store/actions/alert'
import { getPanics, updatePanic } from '../../../store/actions/panic'
import { PieChart } from 'react-easy-chart';
import Chart from 'react-apexcharts';
import MixedChart from '../../../containers/Chartstypes/Apex/MixedChart';
import AlertChart from '../../../containers/Chartstypes/Apex/AlertChart';
import AudienceChart from '../../../containers/Chartstypes/Apex/AudienceChart';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import moment from 'moment'
import { groupBy } from 'lodash'
import { Button, FormText } from 'reactstrap';
import { storage } from 'firebase'
import uuid from 'uuid'

class Home extends Component {

    state = {
        alert: {
            category: '',
            description: '',
            alertPhotos: { localUrl: [], remoteUrl: [] },
            document: { localUrl: [], remoteUrl: [], name: [] },
            location: { gps: {}, address: '' },
            updates: [],
        },
        panic: {
            status: '',
            location: [],
            panics: [],
            isActive: false,
            status: '',
            uid: '',
            user: {},
        },
        comment: '',
        errorMessage: '',
        isEditMode: false,
        itemCount: 10,
        category: 'All'
    }

    dataValidation = () => {
        if (!this.state.alert.category) {
            this.setState({ errorMessage: 'Missing Category' })
            return false
        }
        else if (!this.state.alert.description) {
            this.setState({ errorMessage: 'Missing Description' })
            return false
        }
        else {
            this.setState({ errorMessage: '' })
            return true
        }
    }

    editAlert = async (e) => {
        try {
            e.preventDefault()

            if (!this.dataValidation()) {
                return
            }

            let newUpdates = this.state.alert.updates
            newUpdates.push({
                id: uuid.v4(),
                name: this.props.company.name,
                photo: this.props.company.photo,
                date: new Date().getTime(),
                comment: this.state.comment || 'Status changed to ' + this.state.alert.status,
                status: this.state.alert.status,
                event: this.state.comment ? 'Comment-Update' : 'Status-Update'
            })

            this.setState({ isEditMode: false })

            if (await this.props.editAlert(this.state.alert)) {

            } else {

            }
        } catch (e) {
            console.log(e.message)
        }
    }

    updatePanic = async (e) => {
        try {
            e.preventDefault()

            this.setState({ isEditMode: false })

            if (await this.props.updatePanic(this.state.panic)) {

            } else {

            }
        } catch (e) {
            console.log(e.message)
        }
    }

    deleteAlert = async (e) => {
        try {
            // e.preventDefault()

            if (await this.props.deleteAlert(this.state.alert)) {
                this.setState({})
            } else {

            }
        } catch (e) {
            console.log(e.message)
        }
    }

    editMode = async (e) => {
        try {
            e.preventDefault()
            this.setState({ isEditMode: true })
        } catch (e) {
            console.log(e.message)
        }
    }

    handleDocuments = e => {

        if (e.target.files[0]) {
            const doc = e.target.files[0];
            this.uploadDocument(doc)
        }
    }

    uploadDocument = async (doc) => {
        try {

            const uploadTask = storage().ref(`documents/${doc.name}`).put(doc)

            uploadTask.on(
                "state_changed",
                snapshot => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    this.setState({ progress });
                },
                error => {
                    console.log(error);
                },
                async () => {
                    let url = await storage().ref("documents").child(doc.name).getDownloadURL()
                    let updatedDocument = this.state.alert.document
                    updatedDocument.remoteUrl.push(url)
                    updatedDocument.name.push(doc.name)
                    this.setState({ alert: { ...this.state.alert, document: updatedDocument } });
                    console.log(this.state.alert.document)
                }
            );
        } catch (e) {
            console.log(e)
        }
    }

    handleImages = e => {

        if (e.target.files[0]) {
            const image = e.target.files[0];
            this.uploadImage(image)
        }
    }

    uploadImage = async (image) => {
        try {

            const uploadTask = storage().ref(`images/${image.name}`).put(image);
            uploadTask.on(
                "state_changed",
                snapshot => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    this.setState({ progress });
                },
                error => {
                    console.log(error);
                },
                async () => {
                    let url = await storage().ref("images").child(image.name).getDownloadURL()
                    let updatedalertPhotos = this.state.alert.alertPhotos
                    updatedalertPhotos.remoteUrl.push(url)
                    this.setState({ alert: { ...this.state.alert, alertPhotos: updatedalertPhotos } });
                    console.log(this.state.alert.alertPhotos)
                }
            );
        } catch (e) {
            console.log(e)
        }
    }


    componentWillMount = async () => {
        try {
            await this.props.sync()
            await this.props.getAlerts()
            await this.props.getPanics()
        } catch (e) {
            console.log(e.message)
        }
    }

    render() {
        const data = this.props.alert.alerts
        // const dataWeek = groupBy(data, (result) => moment(result['date'], 'DD/MM/YYYY').startOf('isoWeek'))
        // console.log(this.props.admin)
        return (
            <AUX>
                <div className="page-content-wrapper">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-xl-3">
                                <div className="card m-b-20">
                                    <div className="card-body">
                                        <h4 className="mt-0 header-title">Maintainance</h4>

                                        <div align="center">
                                            <AlertChart data={data.filter(i => i.category === 'Maintainance')} />
                                        </div>

                                        <ul className="list-inline widget-chart m-t-20 m-b-15 text-center">
                                            <li className="list-inline-item">
                                                <h5 className="mb-0">{data.filter(i => i.category === 'Maintainance' && i.status === 'Open').length}</h5>
                                                <p className="text-muted font-14">Open</p>
                                            </li>

                                            <li className="list-inline-item">
                                                <h5 className="mb-0">{data.filter(i => i.category === 'Maintainance' && i.status === 'Pending').length}</h5>
                                                <p className="text-muted font-14">Pending</p>
                                            </li>

                                            <li className="list-inline-item">
                                                <h5 className="mb-0">{data.filter(i => i.category === 'Maintainance' && i.status === 'Closed').length}</h5>
                                                <p className="text-muted font-14">Closed</p>
                                            </li>
                                            <li className="list-inline-item">
                                                <h5 className="mb-0">{data.length}</h5>
                                                <p className="text-muted font-14">Total Incidents</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3">
                                <div className="card m-b-20">
                                    <div className="card-body">
                                        <h4 className="mt-0 header-title">Security</h4>

                                        <div align="center">
                                            <AlertChart data={data.filter(i => i.category === 'Security')} />
                                        </div>

                                        <ul className="list-inline widget-chart m-t-20 m-b-15 text-center">
                                            <li className="list-inline-item">
                                                <h5 className="mb-0">{data.filter(i => i.category === 'Security' && i.status === 'Open').length}</h5>
                                                <p className="text-muted font-14">Open</p>
                                            </li>
                                            <li className="list-inline-item">
                                                <h5 className="mb-0">{data.filter(i => i.category === 'Security' && i.status === 'Pending').length}</h5>
                                                <p className="text-muted font-14">Pending</p>
                                            </li>
                                            <li className="list-inline-item">
                                                <h5 className="mb-0">{data.filter(i => i.category === 'Security' && i.status === 'Closed').length}</h5>
                                                <p className="text-muted font-14">Closed</p>
                                            </li>
                                            <li className="list-inline-item">
                                                <h5 className="mb-0">{data.length}</h5>
                                                <p className="text-muted font-14">Total Incidents</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-3">
                                <div className="card m-b-20">
                                    <div className="card-body">
                                        <h4 className="mt-0 header-title">Administrative</h4>

                                        <div align="center">
                                            <AlertChart data={data.filter(i => i.category === 'Administrative')} />
                                        </div>

                                        <ul className="list-inline widget-chart m-t-20 m-b-15 text-center">
                                            <li className="list-inline-item">
                                                <h5 className="mb-0">{data.filter(i => i.category === 'Administrative' && i.status === 'Open').length}</h5>
                                                <p className="text-muted font-14">Open</p>
                                            </li>
                                            <li className="list-inline-item">
                                                <h5 className="mb-0">{data.filter(i => i.category === 'Administrative' && i.status === 'Pending').length}</h5>
                                                <p className="text-muted font-14">Pending</p>
                                            </li>
                                            <li className="list-inline-item">
                                                <h5 className="mb-0">{data.filter(i => i.category === 'Administrative' && i.status === 'Closed').length}</h5>
                                                <p className="text-muted font-14">Closed</p>
                                            </li>
                                            <li className="list-inline-item">
                                                <h5 className="mb-0">{data.length}</h5>
                                                <p className="text-muted font-14">Total Incidents</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-3">
                                <div className="card m-b-20">
                                    <div className="card-body">
                                        <h4 className="mt-0 header-title">Other</h4>

                                        <div align="center">
                                            <AlertChart data={data.filter(i => i.category === 'Other')} />
                                        </div>

                                        <ul className="list-inline widget-chart m-t-20 m-b-15 text-center">
                                            <li className="list-inline-item">
                                                <h5 className="mb-0">{data.filter(i => i.category === 'Other' && i.status === 'Open').length}</h5>
                                                <p className="text-muted font-14">Open</p>
                                            </li>
                                            <li className="list-inline-item">
                                                <h5 className="mb-0">{data.filter(i => i.category === 'Other' && i.status === 'Pending').length}</h5>
                                                <p className="text-muted font-14">Pending</p>
                                            </li>
                                            <li className="list-inline-item">
                                                <h5 className="mb-0">{data.filter(i => i.category === 'Other' && i.status === 'Closed').length}</h5>
                                                <p className="text-muted font-14">Closed</p>
                                            </li>
                                            <li className="list-inline-item">
                                                <h5 className="mb-0">{data.length}</h5>
                                                <p className="text-muted font-14">Total Incidents</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            {/* <div className="col-xl-2">

                            </div> */}
                            <div className="col-xl-9">
                                <div className="card m-b-20">
                                    <div className="card-body">
                                        <h4 className="mt-0 header-title">Announcements Audience</h4>

                                        <ul className="list-inline widget-chart m-t-20 m-b-15 text-center">
                                            <li className="list-inline-item">
                                                <h5 className="mb-0">{this.props.annoucement.viewStats.viewed}</h5>
                                                <p className="text-muted font-14">Viewed</p>
                                            </li>
                                            <li className="list-inline-item">
                                                <h5 className="mb-0">{this.props.annoucement.viewStats.nonViewed}</h5>
                                                <p className="text-muted font-14">Not Viewed</p>
                                            </li>
                                        </ul>

                                        <div align="center">
                                            <AudienceChart />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-xl-2">

                            </div> */}

                            {/* <div className="col-xl-3">
                                <div className="row">
                                    <div className="col-md-9 pr-md-0">
                                        <div className="card m-b-20" style={{ Height: "486px" }}>
                                            <div className="card-body">
                                                <h4 className="mt-0 header-title">Monthly Summary</h4>

                                                <div className="text-center">
                                                    <div className="btn-group m-t-20" role="group" aria-label="Basic example">
                                                        <button type="button" className="btn btn-secondary">Day</button>
                                                        <button type="button" className="btn btn-secondary">Month</button>
                                                        <button type="button" className="btn btn-secondary">Year</button>
                                                    </div>
                                                </div>
                                                <MixedChart />

                                            </div>
                                        </div>
                                    </div> */}
                            <div className="col-md-3 pl-md-0">
                                <div className="card m-b-20" style={{ Height: "100%" }}>
                                    <div className="card-body">
                                        <div className="m-b-20">
                                            <p>Total Incidents</p>
                                            <h5>{this.props.alert.alerts.length} Cases</h5>
                                            <Sparklines height={54} data={[5, 10, 5, 20, 15, 10, 18, 14, 20, -18, 18, 17, 29, 10, 18]}>
                                                <SparklinesLine color="blue" />
                                            </Sparklines>
                                        </div>
                                        <div className="m-b-20">
                                            <p>Weekly Incidents</p>
                                            <h5>0 Cases</h5>
                                            <Sparklines height={54} data={[5, 10, 5, 20, 15, 10, 18, 14, 20, -18, 18, 17, 29, 10, 18]}>
                                                <SparklinesLine color="blue" />
                                            </Sparklines>
                                        </div>
                                        <div className="m-b-20">
                                            <p>Monthly Incidents</p>
                                            <h5>0 Cases</h5>
                                            <Sparklines height={54} data={[5, 10, -5, 14, 20, -18, 17, 29, -10, 18, 14, 20, -18, 17, 29,]}>
                                                <SparklinesLine color="blue" />
                                            </Sparklines>
                                        </div>
                                        <div className="m-b-20">
                                            <p>Yearly Incidents</p>
                                            <h5>0 Cases</h5>
                                            <Sparklines height={54} data={[5, 10, 5, 20, 18, 17, 29, 15, 17, 29, 10, 18, 14, 20, -18]}>
                                                <SparklinesLine color="blue" />
                                            </Sparklines>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </AUX >
        );
    }
}

const mapStatetoProps = state => {
    return {
        admin: state.admin,
        alert: state.alert,
        annoucement: state.annoucement,
        panic: state.panic
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ sync, getAlerts, editAlert, deleteAlert, getPanics, updatePanic }, dispatch)
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Home));