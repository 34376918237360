import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { updateCompany, getCompany } from '../../../store/actions/company'
import { Button } from 'reactstrap';
import { storage } from 'firebase'

class Branding extends Component {

    state = {
        isEditMode: false,
        errorMessage: '',
        company: {
            name: '',
            slogan: '',
            description: '',
            address: '',
            email: '',
            contacts: '',
            photo: '',
        },
        progress: 0
    }

    componentDidMount = async () => {
        try {
            await this.props.getCompany()
            await this.setState({
                company: { ...this.props.company }
            })
        } catch (e) {
            console.log(e.message)
        }
    }

    dataValidation = () => {
        if (!this.state.company.name) {
            this.setState({ errorMessage: 'You must enter your Company Name' })
            return false
        }
        else if (!this.state.company.photo) {
            this.setState({ errorMessage: 'You must have a company logo' })
            return false
        }
        else {
            this.setState({ errorMessage: '' })
            return true
        }
    }

    updateCompany = async () => {
        try {

            if (!this.dataValidation()) {
                // this.setState({ isEditMode: false })
                return
            }

            if (await this.props.updateCompany(this.state.company)) {
                this.setState({ isEditMode: false })
            }

        } catch (e) {
            console.log(e.message)
        }
    }

    handleChange = e => {
        if (e.target.files[0]) {
            const image = e.target.files[0];
            console.log(image)
            this.uploadImage(image)
        }

    };

    uploadImage = async (image) => {
        try {

            const uploadTask = storage().ref(`images/${image.name}`).put(image);
            uploadTask.on(
                "state_changed",
                snapshot => {
                    // progress function ...
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    this.setState({ progress });
                },
                error => {
                    // Error function ...
                    console.log(error);
                },
                () => {
                    // complete function ...
                    storage()
                        .ref("images")
                        .child(image.name)
                        .getDownloadURL()
                        .then(url => {
                            this.setState({ company: { ...this.state.company, photo: url } });
                        });
                }
            );
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        console.log(this.state.company.photo)
        return (
            <AUX>
                <AUX>
                    <div className="page-content-wrapper">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card m-b-20">
                                        <div className="card-body">

                                            <h4 className="mt-0 header-title">Company Information</h4>
                                            {
                                                this.state.errorMessage ?
                                                    <p style={{ color: 'red' }} className="text-muted m-b-30 font-14 alert-danger">{this.state.errorMessage}</p>
                                                    :
                                                    <p className="text-muted m-b-30 font-14">This information will appear on all your stakeholder communications.</p>
                                            }
                                            <form>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label for="productname">Company Name</label>
                                                            {
                                                                this.state.isEditMode ?
                                                                    <input className="form-control" onChange={(event) => this.setState({ company: { ...this.state.company, name: event.target.value } })} value={this.state.company.name} type="text" placeholder="Company Name" id="example-text-input" />
                                                                    :
                                                                    <p className="font-13 text-muted">{this.props.company.name}</p>
                                                            }
                                                        </div>
                                                        <div className="form-group">
                                                            <label for="productname">Slogan</label>
                                                            {
                                                                this.state.isEditMode ?
                                                                    <input className="form-control" onChange={(event) => this.setState({ company: { ...this.state.company, slogan: event.target.value } })} value={this.state.company.slogan} type="text" placeholder="Company Slogan" id="example-text-input" />
                                                                    :
                                                                    <p className="font-13 text-muted">{this.props.company.slogan}</p>
                                                            }
                                                        </div>
                                                        <div className="form-group">
                                                            <label for="productdesc">Company Description</label>
                                                            {
                                                                this.state.isEditMode ?
                                                                    <textarea className="form-control" onChange={(event) => this.setState({ company: { ...this.state.company, description: event.target.value } })} value={this.state.company.description} id="productdesc" rows="3" />
                                                                    :
                                                                    <p className="font-13 text-muted">{this.props.company.description}</p>
                                                            }
                                                        </div>
                                                        <div className="form-group">
                                                            <label for="manufacturername">Address</label>
                                                            {
                                                                this.state.isEditMode ?
                                                                    <input className="form-control" onChange={(event) => this.setState({ company: { ...this.state.company, address: event.target.value } })} value={this.state.company.address} type="text" placeholder="Company Address" id="example-text-input" />
                                                                    :
                                                                    <p className="font-13 text-muted">{this.props.company.address}</p>
                                                            }
                                                        </div>
                                                        <div className="form-group">
                                                            <label for="manufacturername">Email</label>
                                                            {
                                                                this.state.isEditMode ?
                                                                    <input className="form-control" onChange={(event) => this.setState({ company: { ...this.state.company, email: event.target.value } })} value={this.state.company.email} type="text" placeholder="Company Email" id="example-text-input" />
                                                                    :
                                                                    <p className="font-13 text-muted">{this.props.company.email}</p>
                                                            }
                                                        </div>
                                                        <div className="form-group">
                                                            <label for="manufacturername">Contact Number</label>
                                                            {
                                                                this.state.isEditMode ?
                                                                    <input className="form-control" onChange={(event) => this.setState({ company: { ...this.state.company, contacts: event.target.value } })} value={this.state.company.contacts} type="text" placeholder="Company Contacts" id="example-text-input" />
                                                                    :
                                                                    <p className="font-13 text-muted">{this.props.company.contacts}</p>
                                                            }
                                                        </div>

                                                    </div>
                                                    <div className="col-sm-6 text-center">
                                                        <div className="form-group">
                                                            <label>Company Logo</label>
                                                            <br />
                                                            <img style={{ height: 300, width: 300, marginBottom: 10 }} src={this.state.company.photo ? this.state.company.photo : "assets/images/products/1.jpg"} alt="product img" className="img-fluid " />
                                                            <br />
                                                            <br />
                                                            {
                                                                this.state.isEditMode ?
                                                                    <div className="custom-file">
                                                                        <input
                                                                            onChange={this.handleChange}
                                                                            type="file"
                                                                            className="custom-file-input"
                                                                            id="inputGroupFile01"
                                                                            aria-describedby="inputGroupFileAddon01" />
                                                                        <label style={{ width: '50%' }} color="secondary" className="btn btn-secondary" htmlFor="inputGroupFile01">
                                                                            Upload Image
                                                                </label>
                                                                    </div> : null
                                                            }
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="row">

                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-6 text-center">
                                                        {
                                                            this.state.isEditMode ?
                                                                <Button onClick={() => this.updateCompany()} color="success" size='md' block>Done</Button>
                                                                :
                                                                <Button onClick={() => this.setState({ isEditMode: true })} color="primary" size='md' block>Edit</Button>
                                                        }
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </AUX>
            </AUX >
        );
    }
}

const mapStatetoProps = state => {
    return {
        admin: state.admin,
        company: state.company
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ updateCompany, getCompany }, dispatch)
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Branding));