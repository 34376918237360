import { firestore } from '../../config/firebase'
import { orderBy } from 'lodash'

export const getActivity = () => {
    return async (dispatch, getState) => {
        try {
            let { company } = getState()
            let resolvedActivities = []

            const query = await firestore.collection('activities').where('company', '==', company.name).get()

            query.forEach(async (item) => {
                resolvedActivities.push(item.data())
            })

            console.log(resolvedActivities)
            dispatch({ type: 'UPDATE_ACTIVITIES', payload: orderBy(resolvedActivities, 'date', 'desc') })
        } catch (e) {
            console.log(e.message)
        }
    }

}
