let initialState = {
    panics: [],
    isActive: false,
    status: '',
    uid: '',
    user: {},
    location: [],
    livePanic: {
        id: '',
        uid: '',
        user: {},
        location: [],
        company: '',
        isActive: false
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_PANICS':
            return { ...state, panics: action.payload }
        case 'UPDATE_LIVE_PANIC':
            return { ...state, livePanic: action.payload }
        case 'UPDATE_PANIC_STATUS':
            return { ...state, isActive: action.payload }
        default:
            return state
    }
}