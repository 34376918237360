import {combineReducers} from 'redux'
import user from './user'
import alert from './alert'
import annoucement from './annoucement'
import activity from './activity'
import ui from './ui'
import admin from './admin'
import company from './company'
import panic from './panic'
import residence from './residence'


export default combineReducers({
  admin,
  user,
  alert,
  annoucement,
  activity,
  company,
  ui, 
  panic,
  residence
});