import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom';
import { getAnnoucements, createAnnoucement, editAnnoucement, deleteAnnoucement } from '../../../store/actions/annoucement'
import { Button, FormText } from 'reactstrap';
import { storage } from 'firebase'
import uuid from 'uuid'
import moment from 'moment'

let general = [
    'https://images.unsplash.com/photo-1512314889357-e157c22f938d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
    'https://images.unsplash.com/photo-1496262967815-132206202600?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
    'https://images.unsplash.com/photo-1585222515068-7201a72c4181?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
    'https://images.unsplash.com/photo-1415604934674-561df9abf539?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60'
]
let security = [
    'https://images.unsplash.com/photo-1570044389283-6713c3b1c48b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
    'https://images.unsplash.com/photo-1494863669637-19f383715416?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
    'https://images.unsplash.com/photo-1587826154528-f1adec0a4653?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
    'https://images.unsplash.com/photo-1533052439013-45751c7b7dc2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60'
]
let administrative = [
    'https://images.unsplash.com/photo-1468779036391-52341f60b55d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
    'https://images.unsplash.com/photo-1522579554132-e32810171820?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
    'https://images.unsplash.com/photo-1423592707957-3b212afa6733?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
    'https://images.unsplash.com/photo-1493307100940-ac5f30709573?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60'
]
let promotion = [
    'https://images.unsplash.com/photo-1580828343064-fde4fc206bc6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
    'https://images.unsplash.com/photo-1512106374988-c95f566d39ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
    'https://images.unsplash.com/photo-1532795986-dbef1643a596?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
    'https://images.unsplash.com/photo-1526178613552-2b45c6c302f0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60'
]

class Annoucements extends Component {

    state = {
        annoucement: {
            title: '',
            category: '',
            description: '',
            gallery: { localUrl: [], remoteUrl: [] },
            document: { localUrl: [], remoteUrl: [], name: [] },
            allowResponse: 'No',
            residence: {
                name: ''
            },
            messages: [],
            updates: [],
            photo: '',
            flashLevel: 'Alert'
        },
        errorMessage: '',
        isEditMode: false,
        category: 'All',
        itemCount: 16,
        residenceFilter: 'See All',
        residences: []
    }

    componentDidMount = async () => {
        try {
            await this.props.getAnnoucements()
            let residences = []
            this.props.residences.forEach(item => {
                residences.push(item.name)
            })
            this.setState({ residences })
        } catch (e) {
            console.log(e.message)
        }
    }

    dataValidation = () => {
        if (!this.state.annoucement.title) {
            this.setState({ errorMessage: 'Missing Title' })
            return false
        }
        else if (!this.state.annoucement.category) {
            this.setState({ errorMessage: 'Missing Category' })
            return false
        }
        else if (!this.state.annoucement.description) {
            this.setState({ errorMessage: 'Missing Description' })
            return false
        }
        else {
            this.setState({ errorMessage: '' })
            return true
        }
    }

    createAnnoucement = async (e) => {
        try {
            e.preventDefault()
            if (!this.dataValidation()) {
                return
            }

            console.log(this.state.annoucement)

            if (await this.props.createAnnoucement(this.state.annoucement)) {

            } else {

            }
        } catch (e) {
            console.log(e.message)
        }
    }

    editAnnoucement = async (e) => {
        try {
            e.preventDefault()

            if (!this.dataValidation()) {
                return
            }

            if (this.state.comment) {
                let residence = this.props.residences.find(r => r.id === this.state.annoucement.residenceUid)

                let newMessages = this.state.annoucement.messages
                newMessages.push({
                    _id: uuid.v4(),
                    createdAt: new Date().getTime(),
                    text: this.state.comment,
                    user: {
                        _id: this.props.admin.uid,
                        name: residence.name,
                        avatar: residence.photo,
                    }
                })

                this.setState({ isEditMode: false, annoucement: { ...this.state.annoucement, messages: newMessages } })
            } else {
                this.setState({ isEditMode: false })
            }

            if (await this.props.editAnnoucement(this.state.annoucement)) {

            } else {

            }
        } catch (e) {
            console.log(e.message)
        }
    }

    deleteAnnoucement = async (e) => {
        try {

            if (await this.props.deleteAnnoucement(this.state.annoucement)) {
                this.setState({})
            } else {

            }
        } catch (e) {
            console.log(e.message)
        }
    }

    editMode = async (e) => {
        try {
            e.preventDefault()
            this.setState({ isEditMode: true })
        } catch (e) {
            console.log(e.message)
        }
    }

    handleDocuments = e => {

        if (e.target.files[0]) {
            const doc = e.target.files[0];
            this.uploadDocument(doc)
        }
    }

    uploadDocument = async (doc) => {
        try {

            const uploadTask = storage().ref(`documents/${doc.name}`).put(doc)

            uploadTask.on(
                "state_changed",
                snapshot => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    this.setState({ progress });
                },
                error => {
                    console.log(error);
                },
                async () => {
                    let url = await storage().ref("documents").child(doc.name).getDownloadURL()
                    let updatedDocument = this.state.annoucement.document
                    updatedDocument.remoteUrl.push(url)
                    updatedDocument.name.push(doc.name)
                    this.setState({ annoucement: { ...this.state.annoucement, document: updatedDocument } });
                    console.log(this.state.annoucement.document)
                }
            );
        } catch (e) {
            console.log(e)
        }
    }

    handleImages = e => {

        if (e.target.files[0]) {
            const image = e.target.files[0];
            this.uploadImage(image)
        }
    }

    uploadImage = async (image) => {
        try {

            const uploadTask = storage().ref(`images/${image.name}`).put(image);
            uploadTask.on(
                "state_changed",
                snapshot => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    this.setState({ progress });
                },
                error => {
                    console.log(error);
                },
                async () => {
                    let url = await storage().ref("images").child(image.name).getDownloadURL()
                    let updatedGallery = this.state.annoucement.gallery
                    updatedGallery.remoteUrl.push(url)
                    this.setState({ annoucement: { ...this.state.annoucement, gallery: updatedGallery } });
                    console.log(this.state.annoucement.gallery)
                }
            );
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        const d = this.state.category === 'All' ? this.props.annoucements.slice(0, this.state.itemCount) : this.props.annoucements.slice(0, this.state.itemCount).filter(i => i.category === this.state.category)
        const data = this.state.residenceFilter === 'See All' ? d : d.filter(r => r.residence.name === this.state.residenceFilter)
        const residence = this.props.residences.find(r => r.id === this.state.annoucement.residenceUid)
        // console.log(this.state.annoucement.residence)
        return (
            <AUX>
                <div className="page-content-wrapper">
                    <div className="container-fluid">

                        <div className='row'>
                            <div className="col-3">
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Residence</label>
                                    <div className="col-sm-7">
                                        <select options={this.state.residences} onChange={(event) => this.setState({ residenceFilter: event.target.value })} value={this.state.residenceFilter} className="form-control select2">
                                            <option>See All</option>
                                            {
                                                this.state.residences.map(item => (
                                                    <option>{item}</option>
                                                ))
                                            }

                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Category</label>
                                    <div className="col-sm-7">
                                        <select onChange={(event) => this.setState({ category: event.target.value })} value={this.state.category} className="form-control select2">
                                            <option>All</option>
                                            <option>General</option>
                                            <option>Security</option>
                                            <option>Administrative</option>
                                            <option>Promotion</option>
                                            <option>Flash</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Show Items</label>
                                    <div className="col-sm-7">
                                        <select onChange={(event) => this.setState({ itemCount: event.target.value })} value={this.state.itemCount} className="form-control select2">
                                            <option>16</option>
                                            <option>32</option>
                                            <option>48</option>
                                            <option>54</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3   ">
                                <div className="form-group row">
                                    <div className="col-sm-12">

                                        <button data-toggle="modal" className='btn btn-primary btn-block' onClick={() => this.setState({
                                            annoucement: {
                                                title: '',
                                                category: '',
                                                description: '',
                                                gallery: { localUrl: [], remoteUrl: [] },
                                                document: { localUrl: [], remoteUrl: [], name: [] },
                                                residence: {
                                                    name: ''
                                                },
                                                allowResponse: 'No'
                                            }
                                        })} data-target="#myModal">Create Annoucement</button>

                                        <div id="myModal" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                                            <div style={{ margin: 'auto', paddingTop: 30, paddingBottom: 30, width: '80%' }} className="modal-dialog modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title mt-0" id="myModalLabel">Create Annoucement</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                                    </div>
                                                    <div className="modal-body">
                                                        {
                                                            this.state.errorMessage ?
                                                                <p style={{ color: 'red' }} className="text-muted m-b-30 font-14 alert-danger">{this.state.errorMessage}</p> : null
                                                        }

                                                        <div className="card-body">
                                                            <form>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <div className="form-group">
                                                                            <label for="metatitle">Title</label>
                                                                            <input onChange={(event) => this.setState({ annoucement: { ...this.state.annoucement, title: event.target.value } })} value={this.state.annoucement.title} id="metatitle" name="productname" type="text" className="form-control" />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label for="metadescription">Residence</label>
                                                                            <select onChange={(event) => {
                                                                                let residence = this.props.residences.find(r => r.name === event.target.value)
                                                                                this.setState({ annoucement: { ...this.state.annoucement, residence } })
                                                                                general.unshift(residence.photo)
                                                                                security.unshift(residence.photo)
                                                                                administrative.unshift(residence.photo)
                                                                                promotion.unshift(residence.photo)
                                                                            }

                                                                            } value={this.state.annoucement.residence.name} className="form-control select2">
                                                                                <option>Select Residence</option>
                                                                                {
                                                                                    this.props.residences.map(item => (
                                                                                        <option>{item.name}</option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label className="control-label">Category</label>
                                                                            <select onChange={(event) => this.setState({ annoucement: { ...this.state.annoucement, category: event.target.value } })} value={this.state.annoucement.category} className="form-control select2">
                                                                                <option>Select</option>
                                                                                <option value="General">General</option>
                                                                                <option value="Security">Security</option>
                                                                                <option value="Administrative">Administrative</option>
                                                                                <option value="Promotion">Promotion</option>
                                                                                <option value="Flash">Flash</option>
                                                                            </select>
                                                                        </div>
                                                                        {this.state.annoucement.category !== '' ?
                                                                            <>
                                                                                {
                                                                                    !this.state.annoucement.category === 'Flash' ? <label className="control-label">Annoucement Image</label> : null
                                                                                }

                                                                                <div className="row">
                                                                                    {
                                                                                        this.state.annoucement.category === 'General' ? general.map(item => (
                                                                                            <div className="col-md-4 col-lg-3">
                                                                                                <div style={{ backgroundColor: item === this.state.annoucement.photo ? 'green' : 'white' }} className="product-list-box">
                                                                                                    <div className="row">
                                                                                                        <img onClick={() => {
                                                                                                            this.setState({ annoucement: { ...this.state.annoucement, photo: item } })
                                                                                                        }} style={{ height: 140 }} src={item} className="img-fluid rounded-rounded" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                        )) : null
                                                                                    }
                                                                                    {
                                                                                        this.state.annoucement.category === 'Security' ? security.map(item => (
                                                                                            <div className="col-md-4 col-lg-3">
                                                                                                <div style={{ backgroundColor: item === this.state.annoucement.photo ? 'green' : 'white' }} className="product-list-box">
                                                                                                    <div className="row">
                                                                                                        <img onClick={() => {
                                                                                                            this.setState({ annoucement: { ...this.state.annoucement, photo: item } })
                                                                                                        }} style={{ height: 140 }} src={item} className="img-fluid rounded-rounded" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                        )) : null
                                                                                    }
                                                                                    {
                                                                                        this.state.annoucement.category === 'Administrative' ? administrative.map(item => (
                                                                                            <div className="col-md-4 col-lg-3">
                                                                                                <div style={{ backgroundColor: item === this.state.annoucement.photo ? 'green' : 'white' }} className="product-list-box">
                                                                                                    <div className="row">
                                                                                                        <img onClick={() => {
                                                                                                            this.setState({ annoucement: { ...this.state.annoucement, photo: item } })
                                                                                                        }} style={{ height: 140 }} src={item} className="img-fluid rounded-rounded" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                        )) : null
                                                                                    }
                                                                                    {
                                                                                        this.state.annoucement.category === 'Promotion' ? promotion.map(item => (
                                                                                            <div className="col-md-4 col-lg-3">
                                                                                                <div style={{ backgroundColor: item === this.state.annoucement.photo ? 'green' : 'white' }} className="product-list-box">
                                                                                                    <div className="row">
                                                                                                        <img onClick={() => {
                                                                                                            this.setState({ annoucement: { ...this.state.annoucement, photo: item } })
                                                                                                        }} style={{ height: 140 }} src={item} className="img-fluid rounded-rounded" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                        )) : null
                                                                                    }
                                                                                    {
                                                                                        this.state.annoucement.category === 'Flash' ?
                                                                                            <div style={{ marginTop: 5, marginBottom: 20 }} className="col-md-4 col-lg-3">
                                                                                                <label className="control-label">Flash Level</label>
                                                                                                <select onChange={(event) => this.setState({ annoucement: { ...this.state.annoucement, flashLevel: event.target.value } })} value={this.state.annoucement.flashLevel} className="form-control select2">
                                                                                                    <option>Select</option>
                                                                                                    <option value="Alert">Alert</option>
                                                                                                    <option value="Warning">Warning</option>
                                                                                                    <option value="Information">Information</option>
                                                                                                    <option value="Advert">Advert</option>
                                                                                                </select>
                                                                                            </div>
                                                                                            : null
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                            : null
                                                                        }
                                                                        <div className="form-group">
                                                                            <label className="control-label">Enable Response</label>
                                                                            <select onChange={(event) => this.setState({ annoucement: { ...this.state.annoucement, allowResponse: event.target.value } })} value={this.state.annoucement.allowResponse} className="form-control select2">
                                                                                <option>Select</option>
                                                                                <option value="Yes">Yes</option>
                                                                                <option value="No">No</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label for="metadescription">Description</label>
                                                                            <textarea onChange={(event) => this.setState({ annoucement: { ...this.state.annoucement, description: event.target.value } })} value={this.state.annoucement.description} className="form-control" id="metadescription" rows="5"></textarea>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label for="metadescription">Images</label>
                                                                            <div className='row'>
                                                                                {
                                                                                    this.state.annoucement.gallery.remoteUrl.length > 0 ?
                                                                                        this.state.annoucement.gallery.remoteUrl.map(url => (
                                                                                            <div className='col-sm-4'>
                                                                                                <img style={{ height: 100, width: 100, margin: 8 }} src={url} alt="product img" className="img-fluid" />
                                                                                            </div>
                                                                                        ))
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                            <div className="custom-file">
                                                                                <input
                                                                                    onChange={this.handleImages}
                                                                                    type="file"
                                                                                    className="custom-file-input"
                                                                                    accept="image/x-png,image/gif,image/jpeg"
                                                                                    id="inputGroupFile01"
                                                                                    aria-describedby="inputGroupFileAddon01" />
                                                                                <label style={{ width: '100%', textAlign: 'center' }} color="secondary" className="btn btn-secondary" htmlFor="inputGroupFile01">
                                                                                    Upload Image
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label for="metadescription">Documents</label>
                                                                            <div className='row'>
                                                                                {
                                                                                    this.state.annoucement.document.remoteUrl.length > 0 ?
                                                                                        this.state.annoucement.document.remoteUrl.map((url, index) => (
                                                                                            <div style={{ textAlign: 'center' }} className='col-sm-4 text-center'>
                                                                                                <img style={{ height: 100, width: 100, margin: 8, textAlign: 'center' }} src={"assets/images/doc.png"} alt="product img" className="img-fluid" />
                                                                                                <FormText style={{ textAlign: 'center' }}>{this.state.annoucement.document.name[index]}</FormText>
                                                                                            </div>
                                                                                        ))
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                            <div className="custom-file">
                                                                                <input
                                                                                    onChange={this.handleDocuments}
                                                                                    accept=".pdf"
                                                                                    type="file"
                                                                                    className="custom-file-input"
                                                                                    id="inputGroupFile02"
                                                                                    aria-describedby="inputGroupFileAddon01" />
                                                                                <label style={{ width: '100%', textAlign: 'center' }} color="secondary" className="btn btn-secondary" htmlFor="inputGroupFile02">
                                                                                    Upload Documents
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div >
                                                                            <Button data-target="#myModal" data-toggle="modal" onClick={(e) => this.createAnnoucement(e)} type="primary" color='primary' className="btn btn-success waves-effect waves-light" block>Submit</Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            {
                                                data.map(item => (
                                                    <div className="col-md-6 col-lg-3">
                                                        <div className="product-list-box">
                                                            <a data-toggle="modal" data-target="#viewAnnoucment" style={{ cursor: 'pointer' }} onClick={() => this.setState({ annoucement: item })}>
                                                                <div className='row'>
                                                                    <img style={{ height: 220, width: '100%', justifyContent: 'center' }} src={item.photo} className="img-fluid" alt="work-thumbnail" />
                                                                    <div style={{ textAlign: 'center' }} className="detail col-sm-12">
                                                                        <FormText style={{ fontSize: 18, fontWeight: 'bold' }} className="font-16">{item.title}</FormText>
                                                                        <FormText style={{ fontSize: 14 }} className="font-13">{item.category}</FormText>
                                                                        <FormText style={{ fontSize: 10 }} className="font-13">{moment(item.date).format('llll')}</FormText>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>

                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="viewAnnoucment" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div style={{ margin: 'auto', paddingTop: 30, paddingBottom: 30, width: '80%' }} className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">View Annoucement</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body">
                                {
                                    this.state.errorMessage ?
                                        <p style={{ color: 'red' }} className="text-muted m-b-30 font-14 alert-danger">{this.state.errorMessage}</p> : null
                                }

                                <div className="card-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                {/* <div className="form-group">
                                                    <label for="metatitle">Views</label>
                                                    <p className="text-muted m-b-30 font-14">{this.state.annoucement.views}</p>
                                                </div> */}
                                                <div className="form-group">
                                                    <label for="metatitle">Title</label>
                                                    {
                                                        this.state.isEditMode ?
                                                            <input onChange={(event) => this.setState({ annoucement: { ...this.state.annoucement, title: event.target.value } })} value={this.state.annoucement.title} id="metatitle" name="productname" type="text" className="form-control" />
                                                            :
                                                            <p className="text-muted m-b-30 font-14">{this.state.annoucement.title}</p>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label for="metadescription">Residence</label>
                                                    {
                                                        this.state.isEditMode ?

                                                            <select onChange={(event) => this.setState({ annoucement: { ...this.state.annoucement, residence: this.props.residences.find(r => r.name === event.target.value) } })} value={this.state.annoucement.residence.name} className="form-control select2">

                                                                {
                                                                    this.props.residences.map(item => (
                                                                        <option>{item.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            :
                                                            <p className="text-muted m-b-30 font-14">{this.state.annoucement.residence.name}</p>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label">Category</label>
                                                    {
                                                        this.state.isEditMode ?
                                                            <select onChange={(event) => this.setState({ annoucement: { ...this.state.annoucement, category: event.target.value } })} value={this.state.annoucement.category} className="form-control select2">
                                                                <option>Select</option>
                                                                <option value="General">General</option>
                                                                <option value="Security">Security</option>
                                                                <option value="Administrative">Administrative</option>
                                                                <option value="Promotion">Promotion</option>
                                                                <option value="Flash">Flash</option>
                                                            </select> :
                                                            <p className="text-muted m-b-30 font-14">{this.state.annoucement.category}</p>
                                                    }
                                                </div>
                                                {
                                                    this.state.annoucement.category === 'Flash' ?
                                                        <div className="form-group">
                                                            <label className="control-label">Flash Level</label>
                                                            {
                                                                this.state.isEditMode ?
                                                                    <select onChange={(event) => this.setState({ annoucement: { ...this.state.annoucement, flashLevel: event.target.value } })} value={this.state.annoucement.flashLevel} className="form-control select2">
                                                                        <option>Select</option>
                                                                        <option value="Alert">Alert</option>
                                                                        <option value="Warning">Warning</option>
                                                                        <option value="Information">Information</option>
                                                                    </select> :
                                                                    <p className="text-muted m-b-30 font-14">{this.state.annoucement.flashLevel}</p>
                                                            }
                                                        </div>
                                                        : null
                                                }

                                                <div className="form-group">
                                                    <label className="control-label">Annoucement Image</label>
                                                    {
                                                        !this.state.isEditMode ?
                                                            <div className="col-md-4 col-lg-3">
                                                                <div style={{}} className="product-list-box">
                                                                    <div className="row">
                                                                        <img style={{ height: 140 }} src={this.state.annoucement.photo} className="img-fluid rounded-rounded" />
                                                                    </div>
                                                                </div>
                                                            </div> :

                                                            <div className="row">
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div style={{ backgroundColor: this.props.residences.find(r => r.name === this.state.annoucement.residence.name).photo === this.state.annoucement.photo ? 'green' : 'white' }} className="product-list-box">
                                                                        <div className="row">
                                                                            <img onClick={() => {
                                                                                this.setState({ annoucement: { ...this.state.annoucement, photo: this.props.residences.find(r => r.name === this.state.annoucement.residence.name).photo } })
                                                                            }} style={{ height: 140 }} src={this.props.residences.find(r => r.name === this.state.annoucement.residence.name).photo} className="img-fluid rounded-rounded" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {

                                                                    this.state.annoucement.category === 'General' ? general.map(item => (

                                                                        <div className="col-md-4 col-lg-3">
                                                                            <div style={{ backgroundColor: item === this.state.annoucement.photo ? 'green' : 'white' }} className="product-list-box">
                                                                                <div className="row">
                                                                                    <img onClick={() => {
                                                                                        this.setState({ annoucement: { ...this.state.annoucement, photo: item } })
                                                                                    }} style={{ height: 140 }} src={item} className="img-fluid rounded-rounded" />
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    )) : null
                                                                }
                                                                {
                                                                    this.state.annoucement.category === 'Security' ? security.map(item => (
                                                                        <div className="col-md-4 col-lg-3">
                                                                            <div style={{ backgroundColor: item === this.state.annoucement.photo ? 'green' : 'white' }} className="product-list-box">
                                                                                <div className="row">
                                                                                    <img onClick={() => {
                                                                                        this.setState({ annoucement: { ...this.state.annoucement, photo: item } })
                                                                                    }} style={{ height: 140 }} src={item} className="img-fluid rounded-rounded" />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    )) : null
                                                                }
                                                                {
                                                                    this.state.annoucement.category === 'Administrative' ? administrative.map(item => (
                                                                        <div className="col-md-4 col-lg-3">
                                                                            <div style={{ backgroundColor: item === this.state.annoucement.photo ? 'green' : 'white' }} className="product-list-box">
                                                                                <div className="row">
                                                                                    <img onClick={() => {
                                                                                        this.setState({ annoucement: { ...this.state.annoucement, photo: item } })
                                                                                    }} style={{ height: 140 }} src={item} className="img-fluid rounded-rounded" />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    )) : null
                                                                }
                                                                {
                                                                    this.state.annoucement.category === 'Promotion' ? promotion.map(item => (
                                                                        <div className="col-md-4 col-lg-3">
                                                                            <div style={{ backgroundColor: item === this.state.annoucement.photo ? 'green' : 'white' }} className="product-list-box">
                                                                                <div className="row">
                                                                                    <img onClick={() => {
                                                                                        this.setState({ annoucement: { ...this.state.annoucement, photo: item } })
                                                                                    }} style={{ height: 140 }} src={item} className="img-fluid rounded-rounded" />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    )) : null
                                                                }
                                                            </div>

                                                    }

                                                </div>


                                                <div className="form-group">
                                                    <label className="control-label">Enable Response</label>
                                                    {
                                                        this.state.isEditMode ?
                                                            <select onChange={(event) => this.setState({ annoucement: { ...this.state.annoucement, allowResponse: event.target.value } })} value={this.state.annoucement.allowResponse} className="form-control select2">
                                                                <option>Select</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </select> :
                                                            <p className="text-muted m-b-30 font-14">{this.state.annoucement.category}</p>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label for="metadescription">Description</label>
                                                    {
                                                        this.state.isEditMode ?
                                                            <textarea onChange={(event) => this.setState({ annoucement: { ...this.state.annoucement, description: event.target.value } })} value={this.state.annoucement.description} className="form-control" id="metadescription" rows="5"></textarea>
                                                            :
                                                            <p className="text-muted m-b-30 font-14">{this.state.annoucement.description}</p>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label for="metadescription">Images</label>
                                                    <div className='row'>
                                                        {
                                                            this.state.annoucement.gallery.remoteUrl.length > 0 ?
                                                                this.state.annoucement.gallery.remoteUrl.map(url => (
                                                                    <div className='col-sm-4'>
                                                                        <img style={{ height: 100, width: 100, margin: 8 }} src={url} alt="product img" className="img-fluid" />
                                                                    </div>
                                                                ))
                                                                : null
                                                        }
                                                    </div>
                                                    {
                                                        this.state.isEditMode ?
                                                            <div className="custom-file">
                                                                <input
                                                                    onChange={this.handleImages}
                                                                    type="file"
                                                                    className="custom-file-input"
                                                                    accept="image/x-png,image/gif,image/jpeg"
                                                                    id="inputGroupFile01"
                                                                    aria-describedby="inputGroupFileAddon01" />
                                                                <label style={{ width: '100%', textAlign: 'center' }} color="secondary" className="btn btn-secondary" htmlFor="inputGroupFile01">
                                                                    Upload Image
                                                         </label>
                                                            </div> : null
                                                    }

                                                </div>
                                                <div className="form-group">
                                                    <label for="metadescription">Documents</label>
                                                    <div className='row'>
                                                        {
                                                            this.state.annoucement.document.remoteUrl.length > 0 ?
                                                                this.state.annoucement.document.remoteUrl.map((url, index) => (
                                                                    <div style={{ textAlign: 'center' }} className='col-sm-4 text-center'>
                                                                        <img style={{ height: 100, width: 100, margin: 8, textAlign: 'center' }} src={"assets/images/doc.png"} alt="product img" className="img-fluid" />
                                                                        <FormText style={{ textAlign: 'center' }}>{this.state.annoucement.document.name[index]}</FormText>
                                                                    </div>
                                                                ))
                                                                : null
                                                        }
                                                    </div>
                                                    {
                                                        this.state.isEditMode ?
                                                            <div className="custom-file">
                                                                <input
                                                                    onChange={this.handleDocuments}
                                                                    accept=".pdf"
                                                                    type="file"
                                                                    className="custom-file-input"
                                                                    id="inputGroupFile02"
                                                                    aria-describedby="inputGroupFileAddon01" />
                                                                <label style={{ width: '100%', textAlign: 'center' }} color="secondary" className="btn btn-secondary" htmlFor="inputGroupFile02">
                                                                    Upload Documents
                                                        </label>
                                                            </div> : null
                                                    }

                                                    {
                                                        this.state.annoucement.allowResponse === 'Yes' && this.state.annoucement.messages && this.state.annoucement.messages.length > 0 ?
                                                            <div className="form-group">
                                                                <label for="metadescription">Updates</label>
                                                                {this.state.annoucement.messages.sort((a, b) => b.createdAt - a.createdAt).map(item => (
                                                                    <>
                                                                        {
                                                                            residence.name === item.user.name ?

                                                                                <div style={{ margin: 15, alignItems: 'center', justifyContent: 'center' }} className='row'>
                                                                                    <img style={{ width: 50, height: 50, justifyContent: 'center' }} src={item.user.avatar ? item.user.avatar : 'assets/images/users/avatar.jpg'} className="img-fluid rounded-circle" alt="work-thumbnail" />
                                                                                    <div className="detail col-sm-9">
                                                                                        <FormText style={{ fontSize: 18, fontWeight: 'bold' }} className="font-16">{item.user.name}</FormText>
                                                                                        <FormText style={{ fontSize: 14 }} className="font-13">{item.text}</FormText>
                                                                                        <FormText style={{ fontSize: 12 }} className="font-13">{moment(item.createdAt).format('llll')}</FormText>
                                                                                    </div>
                                                                                </div> :
                                                                                <div style={{ margin: 15, alignItems: 'center', justifyContent: 'center' }} className='row'>
                                                                                    <div style={{ alignSelf: 'flex-end' }} className="detail col-sm-9">
                                                                                        <FormText style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'end' }} className="font-16">{item.user.name}</FormText>
                                                                                        <FormText style={{ fontSize: 14, textAlign: 'end' }} className="font-13">{item.text}</FormText>
                                                                                        <FormText style={{ fontSize: 12, textAlign: 'end' }} className="font-13">{moment(item.createdAt).format('llll')}</FormText>
                                                                                    </div>
                                                                                    <img style={{ width: 50, height: 50, justifyContent: 'center' }} src={item.user.avatar ? item.user.avatar : 'assets/images/users/avatar.jpg'} className="img-fluid rounded-circle" alt="work-thumbnail" />

                                                                                </div>
                                                                        }
                                                                    </>
                                                                ))}
                                                            </div> : null
                                                    }
                                                    {
                                                        this.state.isEditMode && this.state.annoucement.allowResponse === 'Yes' ?
                                                            <div className="form-group">
                                                                <label for="metacomment">Comment</label>
                                                                <textarea onChange={(event) => this.setState({ comment: event.target.value })} value={this.state.comment} className="form-control" id="comment" rows="2"></textarea>
                                                            </div> : null
                                                    }

                                                </div>
                                                <div >
                                                    {
                                                        this.state.isEditMode ?
                                                            <Button onClick={(e) => this.editAnnoucement(e)} type="success" color='primary' block>Done</Button>
                                                            :
                                                            <>
                                                                <Button onClick={(e) => this.editMode(e)} color='primary' block>Edit</Button>
                                                                <Button data-toggle="modal" data-target="#viewAnnoucment" onClick={() => this.deleteAnnoucement()} color='danger' block>Delete</Button>
                                                            </>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AUX >
        );
    }
}

const mapStatetoProps = state => {
    return {
        admin: state.admin,
        annoucements: state.annoucement.annoucements,
        residences: state.residence.residences
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getAnnoucements, createAnnoucement, editAnnoucement, deleteAnnoucement }, dispatch)
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Annoucements));