import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { editProfile, changePassword } from '../../../store/actions/admin'
import { Button } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { storage } from 'firebase'
import uuid from 'uuid'

class Profile extends Component {

    style = {
        isEditMode: false,
        isPasswordChange: false,
        isUploading: false,
        user: {
            firstname: '',
            lastname: '',
            email: '',
            phoneNumber: '',
            photo: '',
            newPassword: '',
            currentPassword: '',
            confirmedPassword: ''
        },
        company: {
            plan: '',
            name: '',
            province: '',
            city: '',
            suburb: '',
            streetName: '',
            streetNumber: '',
            postalCode: '',
            photo: '',
            name: '',
            slogan: '',
            description: '',
            address: '',
            email: '',
            phoneNumbers: '',
        },
        selectedFiles: [],
        image: null,
        url: "",
        progress: 0
    }

    handleChange = e => {
        if (e.target.files[0]) {
            const image = e.target.files[0];
            console.log(image)
            this.uploadImage(image)
        }

    };

    uploadImage = async (image) => {
        try {

            const uploadTask = storage().ref(`images/${image.name}`).put(image);
            uploadTask.on(
                "state_changed",
                snapshot => {
                    // progress function ...
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    this.setState({ progress });
                },
                error => {
                    // Error function ...
                    console.log(error);
                },
                () => {
                    // complete function ...
                    storage()
                        .ref("images")
                        .child(image.name)
                        .getDownloadURL()
                        .then(url => {
                            this.setState({ company: { ...this.state.company, photo: url } });
                        });
                }
            );
        } catch (e) {
            console.log(e)
        }
    }

    componentWillMount = async () => {
        try {
            await this.setState({
                user: { ...this.props.admin },
                company: this.props.admin.company
            })
            console.log(this.props.admin)
        } catch (e) {
            console.log(e.message)
        }
    }

    editProfile = async () => {
        try {
            await this.setState({ isEditMode: false })
            // console.log(this.state.user.photo)
            if (await this.props.editProfile(this.state.user, this.state.company)) {
                await this.setState({
                    user: { ...this.props.admin },
                    company: this.props.admin.company
                })
            }

        } catch (e) {
            console.log(e.message)
        }
    }

    changePassword = async () => {
        try {

            if (this.state.user.newPassword !== this.state.user.confirmedPassword) {
                alert('Password do not match')
                return
            }

            if (await this.props.changePassword(this.state.user.currentPassword, this.state.user.newPassword)) {
                await this.setState({ isPasswordChange: false })
            } else {
                await this.setState({ isPasswordChange: false })
            }

        } catch (e) {
            console.log(e.message)
        }
    }

    render() {
        return (
            <AUX>
                <div className="page-content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card m-b-20">
                                    <div className="card-body">
                                        <h3 className="mt-0 header-title">Profile Information</h3>
                                        <p className="text-muted m-b-30 font-14">Please make sure your profile details are correct and up to date.</p>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="p-20">
                                                    <form action="#">
                                                        <div className="form-group">
                                                            <label>Firstname</label>
                                                            {
                                                                this.state.isEditMode ?
                                                                    <input className="form-control" onChange={(event) => this.setState({ user: { ...this.state.user, firstname: event.target.value } })} value={this.state.user.firstname} type="text" placeholder="Firstname" id="example-text-input" />
                                                                    :
                                                                    <p className="font-13 text-muted">{this.state.user.firstname}</p>
                                                            }

                                                        </div>
                                                        <div className="form-group">
                                                            <label>Email</label>
                                                            {
                                                                this.state.isEditMode ?
                                                                    <input className="form-control" onChange={(event) => this.setState({ user: { ...this.state.user, email: event.target.value } })} value={this.state.user.email} type="text" placeholder="Email" id="example-text-input" />
                                                                    :
                                                                    <p className="font-13 text-muted">{this.state.user.email}</p>
                                                            }
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="p-20">
                                                    <form action="#">

                                                        <div className="form-group">
                                                            <label>Lastname</label>
                                                            {
                                                                this.state.isEditMode ?
                                                                    <input className="form-control" onChange={(event) => this.setState({ user: { ...this.state.user, lastname: event.target.value } })} value={this.state.user.lastname} type="text" placeholder="Lastname" id="example-text-input" />
                                                                    :
                                                                    <p className="font-13 text-muted">{this.state.user.lastname}</p>
                                                            }
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Phonenumber</label>
                                                            {
                                                                this.state.isEditMode ?
                                                                    <input className="form-control" onChange={(event) => this.setState({ user: { ...this.state.user, phoneNumber: event.target.value } })} value={this.state.user.phoneNumber} type="text" placeholder="Phonenumber" id="example-text-input" />
                                                                    :
                                                                    <p className="font-13 text-muted">{this.state.user.phoneNumber}</p>
                                                            }
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                        </div>
                                        <br />

                                        <h3 className="mt-0 header-title">Company Profile Information</h3>
                                        <p className="text-muted m-b-30 font-14">Please make sure your company details are correct and up to date.</p>

                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="p-20">
                                                    <form action="#">
                                                        <div className="form-group">
                                                            <label>Name</label>
                                                            {
                                                                this.state.isEditMode ?
                                                                    <input className="form-control" onChange={(event) => this.setState({ company: { ...this.state.company, name: event.target.value } })} value={this.state.company.name} type="text" placeholder="Name" id="example-text-input" />
                                                                    :
                                                                    <p className="font-13 text-muted">{this.state.company.name}</p>
                                                            }

                                                        </div>
                                                        <div className="form-group">
                                                            <label>Phonenumbers</label>
                                                            {
                                                                this.state.isEditMode ?
                                                                    <input className="form-control" onChange={(event) => this.setState({ company: { ...this.state.company, phoneNumbers: event.target.value } })} value={this.state.company.phoneNumbers} type="text" placeholder="Phone Numbers" id="example-text-input" />
                                                                    :
                                                                    <p className="font-13 text-muted">{this.state.company.phoneNumbers}</p>
                                                            }
                                                        </div>

                                                        <div className="form-group">
                                                            <label>City</label>
                                                            {
                                                                this.state.isEditMode ?
                                                                    <input className="form-control" onChange={(event) => this.setState({ company: { ...this.state.company, city: event.target.value } })} value={this.state.company.city} type="text" placeholder="City" id="example-text-input" />
                                                                    :
                                                                    <p className="font-13 text-muted">{this.state.company.city}</p>
                                                            }
                                                        </div>

                                                        <div className="form-group">
                                                            <label>Country</label>
                                                            {
                                                                this.state.isEditMode ?
                                                                    <input className="form-control" onChange={(event) => this.setState({ company: { ...this.state.company, country: event.target.value } })} value={this.state.company.country} type="text" placeholder="Country" id="example-text-input" />
                                                                    :
                                                                    <p className="font-13 text-muted">{this.state.company.country}</p>
                                                            }
                                                        </div>


                                                        <div className="form-group">
                                                            <label for="productdesc">Description</label>
                                                            {
                                                                this.state.isEditMode ?
                                                                    <textarea className="form-control" onChange={(event) => this.setState({ company: { ...this.state.company, description: event.target.value } })} value={this.state.company.description} id="productdesc" rows="3" />
                                                                    :
                                                                    <p className="font-13 text-muted">{this.props.admin.company.description}</p>
                                                            }
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="p-20">
                                                    <form action="#">

                                                        <div className="form-group">
                                                            <label>Email</label>
                                                            {
                                                                this.state.isEditMode ?
                                                                    <input className="form-control" onChange={(event) => this.setState({ company: { ...this.state.company, email: event.target.value } })} value={this.state.company.email} type="text" placeholder="Email" id="example-text-input" />
                                                                    :
                                                                    <p className="font-13 text-muted">{this.state.company.email}</p>
                                                            }
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Street Address</label>
                                                            {
                                                                this.state.isEditMode ?
                                                                    <input className="form-control" onChange={(event) => this.setState({ company: { ...this.state.company, address: event.target.value } })} value={this.state.company.address} type="text" placeholder="Address" id="example-text-input" />
                                                                    :
                                                                    <p className="font-13 text-muted">{this.state.company.address}</p>
                                                            }
                                                        </div>

                                                        <div className="form-group">
                                                            <label>Province</label>
                                                            {
                                                                this.state.isEditMode ?
                                                                    <input className="form-control" onChange={(event) => this.setState({ company: { ...this.state.company, province: event.target.value } })} value={this.state.company.province} type="text" placeholder="Province" id="example-text-input" />
                                                                    :
                                                                    <p className="font-13 text-muted">{this.state.company.province}</p>
                                                            }
                                                        </div>

                                                        <div className="form-group">
                                                            <label>Postal Code</label>
                                                            {
                                                                this.state.isEditMode ?
                                                                    <input className="form-control" onChange={(event) => this.setState({ company: { ...this.state.company, postalCode: event.target.value } })} value={this.state.company.postalCode} type="text" placeholder="Postal Code" id="example-text-input" />
                                                                    :
                                                                    <p className="font-13 text-muted">{this.state.company.postalCode}</p>
                                                            }
                                                        </div>

                                                        <div className="form-group">
                                                            <label>Slogan</label>
                                                            {
                                                                this.state.isEditMode ?
                                                                    <input className="form-control" onChange={(event) => this.setState({ company: { ...this.state.company, slogan: event.target.value } })} value={this.state.company.slogan} type="text" placeholder="Slogan" id="example-text-input" />
                                                                    :
                                                                    <p className="font-13 text-muted">{this.state.company.slogan}</p>
                                                            }
                                                        </div>

                                                        {
                                                            this.state.isPasswordChange ?
                                                                <div>
                                                                    <div className="form-group mb-0">
                                                                        <label>Current Password</label>
                                                                        <input type="password" value={this.state.user.currentPassword} onChange={(event) => this.setState({ user: { ...this.state.user, currentPassword: event.target.value } })} className="form-control" id="userpassword" placeholder="Current password" />
                                                                    </div>
                                                                    <div className="form-group mb-0">
                                                                        <label>New Password</label>
                                                                        <input type="password" value={this.state.user.newPassword} onChange={(event) => this.setState({ user: { ...this.state.user, newPassword: event.target.value } })} className="form-control" id="userpassword" placeholder="New password" />
                                                                    </div>
                                                                    <div className="form-group mb-0">
                                                                        <label>Confirm New Password</label>
                                                                        <input type="password" value={this.state.user.confirmedPassword} onChange={(event) => this.setState({ user: { ...this.state.user, confirmedPassword: event.target.value } })} className="form-control" id="userpassword" placeholder="Confirm password" />
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </form>
                                                </div>
                                            </div>

                                            <div className="col-md-4 text-center">
                                                <label>Company Logo</label>
                                                <p>
                                                    <img style={{ height: 300, width: 300, objectFit: 'contain', marginBottom: 5 }} alt="200x200" src={this.state.company.photo ? this.state.company.photo : 'assets/images/users/avatar.jpg'} data-holder-rendered="true" />
                                                </p>
                                                <br />
                                                <br />
                                                {
                                                    this.state.isEditMode ?
                                                        <div className="custom-file">
                                                            <input
                                                                onChange={this.handleChange}
                                                                type="file"
                                                                className="custom-file-input"
                                                                id="inputGroupFile01"
                                                                aria-describedby="inputGroupFileAddon01" />
                                                            <label style={{ width: '50%' }} color="secondary" className="btn btn-secondary" htmlFor="inputGroupFile01">
                                                                Upload Image
                                                                </label>
                                                        </div> : null
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row" style={{ width: '80%', textAlign: 'center', alignSelf: 'center', margin: 15 }}>
                                        <div className="col">
                                            {
                                                this.state.isEditMode ?
                                                    <Button onClick={() => this.editProfile()} color="primary" size="md" block>Done</Button>
                                                    :
                                                    <Button onClick={() => this.setState({ isEditMode: true })} color="primary" size="md" block>Edit Profile</Button>
                                            }

                                        </div>
                                        <div className="col">
                                            {
                                                this.state.isPasswordChange ?
                                                    <Button onClick={() => this.changePassword()} color='secondary' size="md" block>Done</Button>
                                                    :
                                                    <Button onClick={() => this.setState({ isPasswordChange: true })} color='secondary' size="md" block>Change Password</Button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </AUX >
        );
    }
}

const mapStatetoProps = state => {
    return {
        admin: state.admin
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ editProfile, changePassword }, dispatch)
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Profile));