let initialState = {
    uid: '',
    id: '',
    firstname: '',
    lastname: '',
    phoneNumber: '',
    company: '',
    role: '',
    photo: { localUrl: '', remoteUrl: '' },
    gallery: { localUrl: [], remoteUrl: [] },
    document: { localUrl: '', remoteUrl: '' },
    category: '',
    date: '',
    description: '',
    annoucements: [],
    views: [],
    viewStats: {
        viewed: 0,
        nonViewed: 0,
        count: 0,
        generalViews: 0,
        securityViews: 0,
        administrativeViews: 0,
        promotionViews: 0,
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_ANNOUCEMENTS':
            return { ...state, annoucements: action.payload }
        case 'UPDATE_ANNOUCEMENTS_VIEWS':
            return { ...state, viewStats: action.payload }
        default:
            return state
    }
}