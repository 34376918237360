import { firestore } from '../../config/firebase'
import { orderBy } from 'lodash'
import uuid from 'uuid'
import { sendNotification } from './index'

export const editAlert = (formData) => {
    return async (dispatch, getState) => {
        try {
            let { alert, activity, company, residence } = getState()

            const newAlert = {
                updatedAt: new Date().getTime(),
                ...formData,
            }

            let newAlerts = alert.alerts.map(item => {
                if (item.id === newAlert.id) {
                    item = newAlert
                } return item
            })

            let res = residence.residences.find(r => r.id === formData.residenceUid)

            dispatch({ type: 'UPDATE_ALERTS', payload: orderBy(newAlerts, 'date', 'desc') })
            await firestore.collection('incidents').doc(newAlert.id).update({
                ...newAlert
            })

            let newActivity = {
                id: uuid.v4(),
                alertId: newAlert.id,
                actorUid: formData.residenceUid,
                actorPhoto: res.photo,
                actorName: formData.residence,
                ownerUid: newAlert.uid,
                ownerName: newAlert.firstname + ' ' + newAlert.lastname,
                ownerPhoto: newAlert.photo.remoteUrl,
                photo: res.photo,
                company: newAlert.company,
                date: new Date().getTime(),
                activity: 'UPDATE-ALERT',
            }

            activity.activities.push(newActivity)
            dispatch({ type: 'UPDATE_ACTIVITIES', payload: orderBy(activity.activities, 'date', 'desc') })
            await firestore.collection('activities').doc(newActivity.id).set(newActivity)

            dispatch(statsUpdate())

            dispatch(sendNotification(newAlert.uid, 'An Incidence has been updated', newAlert.updates[newAlert.updates.length - 1].comment, { alertId: newAlert.id }))

            return true
        } catch (e) {
            console.log(e.message)
            return false
        }
    }
}

export const getAlerts = () => {
    return async (dispatch, getState) => {
        try {
            let { company } = getState().admin
            let resolvedItems = []
            let escalations = []
            let updates = []

            const query = await firestore.collection('incidents').where('companyUid', '==', company.uid).get()

            query.forEach(async (item) => {
                resolvedItems.push(item.data())
            })

            dispatch({ type: 'UPDATE_ALERTS', payload: orderBy(resolvedItems, 'date', 'desc') })

            dispatch(statsUpdate())


            resolvedItems.forEach(item => {
                item.updates.forEach(i => {
                    updates.push(i)
                    if (i.event === 'Escalation') {
                        escalations.push(item)
                    }
                })
            })

            dispatch({ type: 'UPDATE_ESCALATIONS', payload: orderBy(escalations, 'date', 'desc') })
            dispatch({ type: 'UPDATE_ALERT_UPDATES', payload: orderBy(updates, 'date', 'desc') })

        } catch (e) {
            console.log(e.message)
        }
    }
}

export const deleteAlert = (formData) => {
    return async (dispatch, getState) => {
        try {
            let { alert } = getState()

            alert.alerts.splice(formData.id, 1)

            dispatch({ type: 'UPDATE_ALERTS', payload: alert.alerts })

            await firestore.collection('incidents').doc(formData.id).delete()

            dispatch(getAlerts(0))

            return true
        } catch (e) {
            console.log(e.message)
            return false
        }
    }
}

export const statsUpdate = () => {
    return async (dispatch, getState) => {
        try {

            let { alerts } = getState().alert

            const open = alerts.filter(alert => alert.status === 'Open').length

            const pending = alerts.filter(alert => alert.status === 'Pending').length

            const closed = alerts.filter(alert => alert.status === 'Closed').length

            let stats = {
                open: open,

                pending: pending,

                closed: closed
            }

            dispatch({ type: 'UPDATE_STATS', payload: stats })
        } catch (e) {
            alert(e.message)
        }
    }
}