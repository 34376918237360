import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom';
import { login, resetPassword, addAdminUser } from '../../../store/actions/admin'

class Login extends Component {

    state = {
        isBusy: false,
        email: '',
        firstname: '',
        lastname: '',
        phoneNumbers: '',
        password: '',
        rememberMe: false,
        isForgotPassword: false,
        addAdmin: false
    }

    componentDidMount() {

    }

    dataValidation = () => {
        if (!this.state.email) {
            this.setState({ errorMessage: 'Please enter your Email' })
            return false
        }
        else if (!this.state.password) {
            this.setState({ errorMessage: 'Please enter your Password' })
            return false
        }
        else {
            this.setState({ errorMessage: '' })
            return true
        }
    }

    addAdminUser = async (event) => {
        try {
            event.preventDefault()

            if (await this.props.addAdminUser({
                email: this.state.email,
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                phoneNumbers: this.state.phoneNumbers
            })) {

                this.setState({ addAdmin: false })

            } else {
                this.setState({ errorMessage: 'Login failed, please try again later.' })
            }

        } catch (e) {
            console.log(e.message)
        }
    }


    login = async (event) => {
        try {
            event.preventDefault()
            if (!this.dataValidation()) {
                return
            }

            if (await this.props.login(this.state)) {

            } else {
                this.setState({ errorMessage: 'Login failed, please try again later.' })
            }

        } catch (e) {
            console.log(e.message)
        }
    }

    passwordReset = async (event) => {
        try {
            event.preventDefault()
            if (!this.state.email) {
                this.setState({ errorMessage: 'Please enter your Email' })
                return
            }
            if (await this.props.resetPassword(this.state.email)) {
                this.setState({ isForgotPassword: false })
            } else {
                this.setState({ errorMessage: 'Login failed, please try again later.' })
            }

        } catch (e) {
            console.log(e.message)
        }
    }

    render() {
        return (
            <AUX>
                <div className="accountbg"></div>
                <div className="wrapper-page">

                    <div className="card">
                        <div className="card-body">

                            <h3 className="text-center m-0">
                                <Link to="/" onClick={() => this.props.UpdateLoginAgain()} className="logo logo-admin"><img src="assets/images/logo-sm.png" height="50" alt="logo" /></Link>
                            </h3>

                            <div className="p-3">
                                <h4 className="font-18 m-b-5 text-center">Welcome!</h4>
                                {
                                    !this.state.isForgotPassword ?
                                        <p className="text-muted text-center">{this.state.errorMessage ? this.state.errorMessage : 'Sign in to continue to Ghea.'}</p>
                                        :
                                        <p className="text-muted text-center">Enter your email address</p>
                                }

                                <form className="form-horizontal m-t-30" action="/">

                                    <div className="form-group">
                                        <label for="useremail">Email</label>
                                        <input type="email" value={this.state.email} onChange={(event) => this.setState({ email: event.target.value })} className="form-control" id="useremail" placeholder="Enter email" />
                                    </div>

                                    {
                                        !this.state.isForgotPassword ?
                                            <div>
                                                <div className="form-group">
                                                    <label for="userpassword">Password</label>
                                                    <input type="password" value={this.state.password} onChange={(event) => this.setState({ password: event.target.value })} className="form-control" id="userpassword" placeholder="Enter password" />
                                                </div>

                                                <div className="form-group">
                                                    <div className="col-sm-12 text-center">
                                                        <button onClick={event => this.login(event)} className="btn btn-primary btn-block w-md waves-effect waves-light" type="submit">Log In</button>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <div className="col-sm-12 text-center">
                                                        <button onClick={() => this.setState({ isForgotPassword: true })} className="btn btn-primary btn-block w-md waves-effect waves-light" type="submit">Reset Password</button>
                                                    </div>
                                                </div>

                                            </div>
                                            :
                                            <div>
                                                <div className="form-group">
                                                    <div className="col-sm-12 text-center">
                                                        <button onClick={event => this.passwordReset(event)} className="btn btn-primary btn-block w-md waves-effect waves-light" type="submit">Submit</button>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="col-sm-12 text-center">
                                                        <button onClick={(event) => {
                                                            event.preventDefault()
                                                            this.setState({ isForgotPassword: false })
                                                        }
                                                        } className="btn btn-primary btn-block w-md waves-effect waves-light" type="submit">Back</button>
                                                    </div>
                                                </div>
                                            </div>
                                    }


                                    {
                                        this.state.addAdmin ?
                                            <div>
                                                <div className="form-group">
                                                    <label for="useremail">Email</label>
                                                    <input type="email" value={this.state.email} onChange={(event) => this.setState({ email: event.target.value })} className="form-control" id="useremail" placeholder="Enter email" />
                                                </div>
                                                <div className="form-group">
                                                    <label for="useremail">Firstname</label>
                                                    <input type="email" value={this.state.firstname} onChange={(event) => this.setState({ firstname: event.target.value })} className="form-control" id="useremail" placeholder="Firstname" />
                                                </div>
                                                <div className="form-group">
                                                    <label for="useremail">Lastname</label>
                                                    <input type="email" value={this.state.lastname} onChange={(event) => this.setState({ lastname: event.target.value })} className="form-control" id="useremail" placeholder="Lastname" />
                                                </div>
                                                <div className="form-group">
                                                    <label for="useremail">Phonenumbers</label>
                                                    <input type="email" value={this.state.phoneNumbers} onChange={(event) => this.setState({ phoneNumbers: event.target.value })} className="form-control" id="useremail" placeholder="Phonenumbers" />
                                                </div>
                                                <div className="form-group">
                                                    <div className="col-sm-12 text-center">
                                                        <button onClick={event => this.addAdminUser(event)} className="btn btn-primary btn-block w-md waves-effect waves-light" type="submit">Add User</button>
                                                    </div>
                                                </div>

                                            </div> : null
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </AUX>
        );
    }
}

const mapStatetoProps = state => {
    return {
        loginpage: state.ui.loginpage,
        admin: state.admin
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ login, resetPassword, addAdminUser }, dispatch)
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Login));
