import { firestore } from '../../config/firebase'
import { orderBy } from 'lodash'
import { notifyAll } from './index'
import { createAnnoucement } from './annoucement'
import uuid from 'uuid'

export const createResidence = (formData) => {
    return async (dispatch, getState) => {
        try {
            let { admin, company, residence, activity } = getState()

            const newResidence = {
                id: uuid.v4(),
                uid: admin.uid,
                company: admin.company.name,
                companyUid: admin.company.uid,
                phoneNumbers: admin.company.phoneNumbers || '',
                date: new Date().getTime(),
                createAt: new Date().getTime(),
                users: [],
                agents: [],
                guidelines: [],
                ...formData,
            }

            residence.residences.push(newResidence)
            dispatch({ type: 'UPDATE_RESIDENCES', payload: orderBy(residence.residences, 'date', 'desc') })

            await firestore.collection('residences').doc(newResidence.id).set(newResidence)

            let newActivity = {
                id: uuid.v4(),
                residenceId: newResidence.id,
                actorUid: admin.uid,
                actorPhoto: newResidence.photo,
                actorName: admin.firstname + " " + admin.lastname,
                ownerUid: admin.company.uid,
                ownerName: admin.company.name,
                ownerPhoto: admin.company.photo,
                photo: newResidence.photo,
                companyUid: admin.company.uid,
                date: new Date().getTime(),
                activity: 'NEW-RESIDENCE',
            }

            activity.activities.push(newActivity)
            dispatch({ type: 'UPDATE_ACTIVITIES', payload: orderBy(activity.activities, 'date', 'desc') })
            await firestore.collection('activities').doc(newActivity.id).set(newActivity)

            const newAnnoucement = {
                id: uuid.v4(),
                uid: admin.uid,
                residenceUid: newResidence.id,
                companyUid: admin.company.uid,
                date: new Date().getTime(),
                createAt: new Date().getTime(),
                photo: newResidence.photo || '',
                views: [],
                updates: [],
                messages: [],
                title: 'Welcome',
                category: 'General',
                gallery: { localUrl: [], remoteUrl: [] },
                document: { localUrl: [], remoteUrl: [], name: [] },
                allowResponse: 'No',
                residence: newResidence,
                flashLevel: 'Alert',
                description: "Welcome to the Ghea Platform we will be using this platform to stay in touch.",
            }

            dispatch(createAnnoucement(newAnnoucement))

            // dispatch(notifyAll('A new Annoucement has been added.', newAnnoucement.title, { annoucementId: newAnnoucement.id }))

            return true
        } catch (e) {
            console.log(e.message)
            return false
        }
    }
}

export const editResidence = (formData) => {
    return async (dispatch, getState) => {
        try {
            let { residence } = getState()

            const updatedResidence = {
                updatedDate: new Date().getTime(),
                ...formData,
            }

            let newResidences = residence.residences.map(item => {
                if (item.id === updatedResidence.id) {
                    item = updatedResidence
                } return item
            })


            if (newResidences.length > 0) {
                dispatch({ type: 'UPDATE_RESIDENCES', payload: orderBy(newResidences, 'date', 'desc') })
            }

            await firestore.collection('residences').doc(updatedResidence.id).update({
                ...updatedResidence
            })
            return true
        } catch (e) {
            console.log(e.message)
            return false
        }
    }
}

export const deleteResidence = (formData) => {
    return async (dispatch, getState) => {
        try {
            let { residence } = getState()

            residence.residences.splice(formData.id, 1)

            dispatch({ type: 'UPDATE_RESIDENCES', payload: residence.residences })

            await firestore.collection('residences').doc(formData.id).delete()

            await dispatch(getResidences())
            return true
        } catch (e) {
            console.log(e.message)
            return false
        }
    }
}

export const getResidences = () => {
    return async (dispatch, getState) => {
        try {
            let { admin } = getState()
            let resolvedItems = []

            const query = await firestore.collection('residences').where('companyUid', '==', admin.company.uid).get()
            query.forEach(async (item) => {
                resolvedItems.push(item.data())
            })

            console.log(resolvedItems)

            dispatch({ type: 'UPDATE_RESIDENCES', payload: resolvedItems })

        } catch (e) {
            console.log(e.message)
        }
    }
}