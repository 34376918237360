let initialState = {
    id: '',
    annoucementId: '',
    alertId: '',
    messageId: '',
    actorUid: '',
    actorPhoto: '',
    actorName: '',
    ownerUid: '',
    ownerName: '',
    ownerPhoto: '',
    photo: '',
    company: '',
    date: '',
    activity: '',
    unseen: 0,
    activities: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_ACTIVITIES_UNSEEN':
            return { ...state, unseen: action.payload }
        case 'UPDATE_ACTIVITIES':
            return { ...state, activities: action.payload }
        default:
            return state
    }
};