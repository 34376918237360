import { firestore } from '../../config/firebase'
import uuid from 'uuid'

export const getCompany = () => {
    return async (dispatch, getState) => {
        try {
            const admin = getState().admin
            const query = await firestore.collection('companies').where('admins', 'array-contains', admin.uid).get()
            let resolvedItem = []
            query.forEach((response) => {
                resolvedItem.push(response.data())
            })

            if (resolvedItem.length <= 0) {
                dispatch({ type: 'UPDATE_COMPANY', payload: {} })
            } else {
                dispatch({ type: 'UPDATE_COMPANY', payload: resolvedItem[0] })
            }

            return resolvedItem
        } catch (e) {
            console.log(e.message)
        }
    }
}

export const updateCompany = (formData) => {
    return async (dispatch, getState) => {
        try {
            const { admin, company } = getState()

            if (company && company.id) {
                const updatedCompany = {
                    uid: admin.uid,
                    ...formData
                }

                dispatch({ type: 'UPDATE_COMPANY', payload: updatedCompany })
                await firestore.collection('company').doc(updatedCompany.id).update({
                    ...updatedCompany
                })
                return true
            } else {
                const newCompany = {
                    id: uuid.v4(),
                    uid: admin.uid,
                    ...formData
                }

                dispatch({ type: 'UPDATE_COMPANY', payload: newCompany })
                await firestore.collection('company').doc(newCompany.id).set(newCompany)
                return true
            }
        } catch (e) {
            console.log(e.message)
        }
    }
}