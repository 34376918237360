import { firestore } from '../../config/firebase'
import { orderBy } from 'lodash'
import { notifyAllResidents } from './index'
import uuid from 'uuid'

export const createAnnoucement = (formData) => {
    return async (dispatch, getState) => {
        try {
            let { admin, annoucement, activity } = getState()

            const newAnnoucement = {
                id: uuid.v4(),
                uid: admin.uid,
                residenceUid: formData.residence.id,
                companyUid: admin.company.uid,
                date: new Date().getTime(),
                createAt: new Date().getTime(),
                photo: formData.photo || formData.residence.photo,
                views: [],
                updates: [],
                messages: [],
                ...formData,
                description: "Dear Residents \n\n" + formData.description + "\n\nRegards\nManagement Team",
            }

            annoucement.annoucements.push(newAnnoucement)
            dispatch({ type: 'UPDATE_ANNOUCEMENTS', payload: orderBy(annoucement.annoucements, 'date', 'desc') })

            await firestore.collection('noticeboard').doc(newAnnoucement.id).set(newAnnoucement)

            let newActivity = {
                id: uuid.v4(),
                annoucementId: newAnnoucement.id,
                residenceUid: newAnnoucement.residence.id,
                actorUid: admin.uid,
                actorPhoto: newAnnoucement.residence.photo,
                actorName: newAnnoucement.residence.name,
                ownerUid: 'ALL',
                ownerName: newAnnoucement.residence.name,
                ownerPhoto: newAnnoucement.residence.photo,
                photo: newAnnoucement.residence.photo,
                residence: newAnnoucement.residence.name,
                date: new Date().getTime(),
                activity: 'NEW-ANNOUCEMENT',
            }

            activity.activities.push(newActivity)
            dispatch({ type: 'UPDATE_ACTIVITIES', payload: orderBy(activity.activities, 'date', 'desc') })
            await firestore.collection('activities').doc(newActivity.id).set(newActivity)

            dispatch(notifyAllResidents(newAnnoucement.residenceUid, 'A new Annoucement has been added.', newAnnoucement.title, { annoucementId: newAnnoucement.id }))

            return true
        } catch (e) {
            console.log(e.message)
            return false
        }
    }
}

export const editAnnoucement = (formData) => {
    return async (dispatch, getState) => {
        try {
            let { admin, annoucement } = getState()

            const newAnnoucement = {
                updatedAt: new Date().getTime(),
                ...formData,
            }

            let newAnnoucements = annoucement.annoucements.map(item => {
                if (item.id === newAnnoucement.id) {
                    item = newAnnoucement
                } return item
            })


            if (newAnnoucements.length > 0) {
                dispatch({ type: 'UPDATE_ANNOUCEMENTS', payload: orderBy(newAnnoucements, 'date', 'desc') })
            }

            await firestore.collection('noticeboard').doc(newAnnoucement.id).update({
                ...newAnnoucement
            })
            return true
        } catch (e) {
            console.log(e.message)
            return false
        }
    }
}

export const deleteAnnoucement = (formData) => {
    return async (dispatch, getState) => {
        try {
            let { annoucement } = getState()

            annoucement.annoucements.splice(formData.id, 1)

            dispatch({ type: 'UPDATE_ANNOUCEMENTS', payload: annoucement.annoucements })

            await firestore.collection('noticeboard').doc(formData.id).delete()

            return true
        } catch (e) {
            console.log(e.message)
            return false
        }
    }
}

export const getAnnoucements = () => {
    return async (dispatch, getState) => {
        try {
            let { company } = getState().admin
            let resolvedItems = []
            let generalViews = 0
            let securityViews = 0
            let administrativeViews = 0
            let promotionViews = 0
            let flashViews = 0

            const query = await firestore.collection('noticeboard').where('companyUid', '==', company.uid).get()
            query.forEach(async (item) => {
                resolvedItems.push(item.data())

                if (item.data().category === 'General') {
                    generalViews = generalViews + item.data().views.length
                }
                else if (item.data().category === 'Security') {
                    securityViews = securityViews + item.data().views.length
                }
                else if (item.data().category === 'Administrative') {
                    administrativeViews = administrativeViews + item.data().views.length
                }
                else if (item.data().category === 'Promotion') {
                    promotionViews = promotionViews + item.data().views.length
                }
                else if (item.data().category === 'Flash') {
                    flashViews = flashViews + item.data().views.length
                }

            })

            dispatch({ type: 'UPDATE_ANNOUCEMENTS', payload: orderBy(resolvedItems, 'date', 'desc') })

            let viewStats = {
                viewed: generalViews + securityViews + administrativeViews + promotionViews + flashViews,
                nonViewed: 0,
                count: resolvedItems.length,
                generalViews: generalViews,
                securityViews: securityViews,
                administrativeViews: administrativeViews,
                promotionViews: promotionViews,
                flashViews: flashViews
            }

            viewStats.nonViewed = viewStats.count - viewStats.viewed


            dispatch({ type: 'UPDATE_ANNOUCEMENTS_VIEWS', payload: viewStats })

        } catch (e) {
            console.log(e.message)
        }
    }
}