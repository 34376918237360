let initialState = {
    users: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_USERS':
            return { ...state, users: action.payload }
        default:
            return state
    }
}