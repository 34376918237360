let initialState = {
    uid: '',
    firstname: '',
    lastname: '',
    email: '',
    role: '',
    phoneNumber: '',
    photo: { localUrl: '', remoteUrl: '' },
    isVerified: false,
    token: '',
    isAuthenticated: false,
    company: {},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_COMPANY':
            return { ...state, company: action.payload }
        case 'UPDATE_AUTH_STATUS':
            return { ...state, isAuthenticated: action.payload }
        case 'UPDATE_ROLE':
            return { ...state, role: action.payload }
        case 'UPDATE_TOKEN':
            return { ...state, token: action.payload }
        case 'UPDATE_ADMIN':
            return action.payload
        default:
            return state
    }
}