const env = {
    apiKey: "AIzaSyBOmZnrE2uWLpapczZ1Ewn-J2ASRJpYuKU",
    authDomain: "ghealive-d8483.firebaseapp.com",
    databaseURL: "https://ghealive-d8483.firebaseio.com",
    projectId: "ghealive-d8483",
    storageBucket: "ghealive-d8483.appspot.com",
    messagingSenderId: "1028432435345",
    googleApiKey: "AIzaSyC9_5Df-MFXEocqFfEXrWGE2HtL6BUmZgA"
}

export default env