let initialState = {
    admins: [],
    uid: '',
    name: '',
    slogan: '',
    description: '',
    address: '',
    email: '',
    contacts: '',
    photo: '',
    plan: '',
    residences: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_COMPANY':
            return action.payload;
        case 'UPDATE_COMPANY_RESIDENCES':
            return { ...state, residences: action.payload }
        default:
            return state
    }
}