import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import moment from 'moment'
import { deleteUser, getUsers, createUser, editUser } from '../../../store/actions/user'
import { Button, FormText } from 'reactstrap';

class Users extends Component {

    state = {
        user: {
            firstname: '',
            lastname: '',
            email: '',
            phoneNumbers: '',
            residence: '',
            unit: '',
            role: '',
        },
        itemCount: 10,
        isEditMode: false,
        residenceFilter: 'See All',
        residences: [],
    }

    deleteUser = async (e) => {
        try {
            if (await this.props.deleteUser(this.state.user.uid)) {
            } else {

            }
        } catch (e) {
            console.log(e.message)
        }
    }

    componentDidMount = async () => {
        try {
            await this.props.getUsers()
            let residences = []

            this.props.residence.residences.forEach(item => {
                residences.push(item.name)
            })
            this.setState({ residences })
        } catch (e) {
            console.log(e.message)
        }
    }

    dataValidation = () => {
        if (!this.state.user.firstname) {
            this.setState({ errorMessage: 'Missing Firstname' })
            return false
        }
        else if (!this.state.user.lastname) {
            this.setState({ errorMessage: 'Missing Lastname' })
            return false
        }
        else if (!this.state.user.email) {
            this.setState({ errorMessage: 'Missing Email' })
            return false
        }
        else if (!this.state.user.residence) {
            this.setState({ errorMessage: 'Missing Residence' })
            return false
        }
        else {
            this.setState({ errorMessage: '' })
            return true
        }
    }

    createUser = async (e) => {
        try {
            e.preventDefault()
            if (!this.dataValidation()) {
                return
            }

            if (await this.props.createUser(this.state.user)) {

            } else {

            }
        } catch (e) {
            console.log(e.message)
        }
    }

    editUser = async (e) => {
        try {
            e.preventDefault()

            if (!this.dataValidation()) {
                return
            }
            this.setState({ isEditMode: false })

            if (await this.props.editUser(this.state.user)) {

            } else {

            }
        } catch (e) {
            console.log(e.message)
        }
    }

    editMode = async (e) => {
        try {
            e.preventDefault()
            this.setState({ isEditMode: true })
        } catch (e) {
            console.log(e.message)
        }
    }

    render() {
        const data = this.state.residenceFilter === 'See All' ? this.props.users.slice(0, this.state.itemCount) : this.props.users.slice(0, this.state.itemCount).filter(i => i.residence === this.state.residenceFilter)
        console.log(data)
        return (
            <AUX>
                <div className="page-content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            {/* <div className="col-3">
                                <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-2 col-form-label">Search</label>
                                    <div className="col-sm-8">
                                        <input className="form-control" type="text" placeholder="search" id="example-text-input" />
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-3">
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Residence</label>
                                    <div className="col-sm-7">
                                        <select options={this.state.residences} onChange={(event) => this.setState({ residenceFilter: event.target.value })} value={this.state.residenceFilter} className="form-control select2">
                                            <option>See All</option>
                                            {
                                                this.state.residences.map(item => (
                                                    <option>{item}</option>
                                                ))
                                            }

                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Show Items</label>
                                    <div className="col-sm-7">
                                        <select onChange={(event) => this.setState({ itemCount: event.target.value })} value={this.state.itemCount} className="form-control select2">
                                            <option>10</option>
                                            <option>25</option>
                                            <option>50</option>
                                            <option>100</option>
                                            <option>200</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group row">
                                    <div className="col-sm-12">

                                        <button data-toggle="modal" className='btn btn-primary btn-block' onClick={() => this.setState({
                                            user: {
                                                firstname: '',
                                                lastname: '',
                                                email: '',
                                                phoneNumbers: '',
                                                residence: '',
                                                unit: '',
                                                photo: { localUrl: '', remoteUrl: '' },
                                                role: ''
                                            }
                                        })} data-target="#myModal">Add User</button>

                                        <div id="myModal" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                                            <div style={{ margin: 'auto', paddingTop: 30, paddingBottom: 30, width: '80%' }} className="modal-dialog modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title mt-0" id="myModalLabel">Add User</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                                    </div>
                                                    <div className="modal-body">
                                                        {
                                                            this.state.errorMessage ?
                                                                <p style={{ color: 'red' }} className="text-muted m-b-30 font-14 alert-danger">{this.state.errorMessage}</p> : null
                                                        }

                                                        <div className="card-body">
                                                            <form>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <div className="form-group">
                                                                            <label for="metadescription">Role</label>
                                                                            <div className="col-sm-7">
                                                                                <select onChange={(event) => this.setState({ user: { ...this.state.user, role: event.target.value } })} value={this.state.user.role} className="form-control select2">
                                                                                    <option>Select Role</option>
                                                                                    <option>Resident</option>
                                                                                    <option>Agent</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label for="metadescription">Residence</label>
                                                                            <div className="col-sm-7">
                                                                                <select onChange={(event) => this.setState({ user: { ...this.state.user, residence: event.target.value } })} value={this.state.user.residence} className="form-control select2">
                                                                                    <option>Select Residence</option>
                                                                                    {
                                                                                        this.state.residences.map(item => (
                                                                                            <option>{item}</option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label for="metadescription">Unit</label>
                                                                            <input onChange={(event) => this.setState({ user: { ...this.state.user, unit: event.target.value } })} value={this.state.user.unit} className="form-control" id="unit" rows="5"></input>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label for="metatitle">Firstname</label>
                                                                            <input onChange={(event) => this.setState({ user: { ...this.state.user, firstname: event.target.value } })} value={this.state.user.firstname} id="metatitle" name="productname" type="text" className="form-control" />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label for="metadescription">Lastname</label>
                                                                            <input onChange={(event) => this.setState({ user: { ...this.state.user, lastname: event.target.value } })} value={this.state.user.lastname} className="form-control" id="lastname" rows="5"></input>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label for="metadescription">Email</label>
                                                                            <input onChange={(event) => this.setState({ user: { ...this.state.user, email: event.target.value } })} value={this.state.user.email} className="form-control" id="email" rows="5"></input>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label for="metadescription">Phone Number</label>
                                                                            <input onChange={(event) => this.setState({ user: { ...this.state.user, phoneNumbers: event.target.value } })} value={this.state.user.phoneNumbers} className="form-control" id="phonenumber" rows="5"></input>
                                                                        </div>

                                                                        <div>
                                                                            <Button data-target="#myModal" data-toggle='modal' onClick={(e) => this.createUser(e)} type="primary" color='primary' className="btn btn-success waves-effect waves-light" block>Submit</Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12">
                                <div className="card m-b-20">
                                    <div className="card-body">
                                        <h4 className="mt-0 m-b-30 header-title">User Directory</h4>
                                        <div className="table-responsive">
                                            <table className="table table-vertical mb-0">
                                                <tbody>
                                                    <tr>
                                                        <td style={{ fontWeight: 'bold' }}>
                                                            Names
                                                        </td>

                                                        <td style={{ fontWeight: 'bold' }}>
                                                            Role
                                                        </td>
                                                        <td style={{ fontWeight: 'bold' }}>
                                                            Residence
                                                        </td>
                                                        <td style={{ fontWeight: 'bold' }}>
                                                            Unit
                                                        </td>
                                                        <td style={{ fontWeight: 'bold' }}>
                                                            Access Code
                                                        </td>
                                                        <td style={{ fontWeight: 'bold' }}>
                                                            Phone Numbers
                                                        </td>
                                                        <td style={{ fontWeight: 'bold' }}>
                                                            Email
                                                        </td>
                                                        <td style={{ fontWeight: 'bold' }}>
                                                            Action
                                                        </td>
                                                    </tr>
                                                    {
                                                        data.map(item => (
                                                            <tr>
                                                                <td>
                                                                    {item.firstname}  {item.lastname}
                                                                </td>

                                                                <td>
                                                                    {item.role}
                                                                </td>
                                                                <td>
                                                                    {item.residence}
                                                                </td>
                                                                <td>
                                                                    {item.unit}
                                                                </td>
                                                                <td>
                                                                    {item.accessCode}
                                                                </td>
                                                                <td>
                                                                    {item.phoneNumbers}
                                                                </td>
                                                                <td>
                                                                    {item.email}
                                                                </td>
                                                                <td>
                                                                    <button onClick={() => this.setState({ user: item })} data-toggle="modal" data-target="#viewUser" type="button" className="btn btn-success btn-sm waves-effect">View</button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="viewUser" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                        <div style={{ margin: 'auto', paddingTop: 30, paddingBottom: 30, width: '80%' }} className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0" id="myModalLabel">View User</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                </div>
                                <div className="modal-body">
                                    {
                                        this.state.errorMessage ?
                                            <p style={{ color: 'red' }} className="text-muted m-b-30 font-14 alert-danger">{this.state.errorMessage}</p> : null
                                    }

                                    <div className="card-body">
                                        <form>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label for="metadescription">Role</label>
                                                        {
                                                            this.state.isEditMode ?
                                                                <div className="col-sm-7">
                                                                    <select onChange={(event) => this.setState({ user: { ...this.state.user, role: event.target.value } })} value={this.state.user.role} className="form-control select2">
                                                                        <option>Select Role</option>ƒ
                                                                        <option>Resident</option>
                                                                        <option>Agent</option>
                                                                    </select>
                                                                </div> :
                                                                <p className="text-muted m-b-30 font-14">{this.state.user.role}</p>
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="metadescription">Residence</label>
                                                        {
                                                            this.state.isEditMode ?
                                                                <div className="col-sm-7">
                                                                    <select onChange={(event) => this.setState({ user: { ...this.state.user, residence: event.target.value } })} value={this.state.user.residence} className="form-control select2">
                                                                        <option>Select Residence</option>
                                                                        {
                                                                            this.state.residences.map(item => (
                                                                                <option>{item}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div> :
                                                                <p className="text-muted m-b-30 font-14">{this.state.user.residence}</p>
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="metadescription">Unit</label>
                                                        {
                                                            this.state.isEditMode ?
                                                                <input onChange={(event) => this.setState({ user: { ...this.state.user, unit: event.target.value } })} value={this.state.user.unit} className="form-control" id="metadescription" rows="5"></input>
                                                                :
                                                                <p className="text-muted m-b-30 font-14">{this.state.user.unit}</p>
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="metatitle">Firstname</label>
                                                        {
                                                            this.state.isEditMode ?
                                                                <input onChange={(event) => this.setState({ user: { ...this.state.user, firstname: event.target.value } })} value={this.state.user.firstname} id="metatitle" name="name" type="text" className="form-control" />
                                                                :
                                                                <p className="text-muted m-b-30 font-14">{this.state.user.firstname}</p>
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="metadescription">Last Name</label>
                                                        {
                                                            this.state.isEditMode ?
                                                                <input onChange={(event) => this.setState({ user: { ...this.state.user, lastname: event.target.value } })} value={this.state.user.lastname} className="form-control" id="metadescription" rows="5"></input>
                                                                :
                                                                <p className="text-muted m-b-30 font-14">{this.state.user.lastname}</p>
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="metadescription">Email</label>
                                                        {
                                                            this.state.isEditMode ?
                                                                <input onChange={(event) => this.setState({ user: { ...this.state.user, email: event.target.value } })} value={this.state.user.email} className="form-control" id="metadescription" rows="5"></input>
                                                                :
                                                                <p className="text-muted m-b-30 font-14">{this.state.user.email}</p>
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="metadescription">Phone Numbers</label>
                                                        {
                                                            this.state.isEditMode ?
                                                                <input onChange={(event) => this.setState({ user: { ...this.state.user, phoneNumbers: event.target.value } })} value={this.state.user.phoneNumbers} className="form-control" id="metadescription" rows="5"></input>
                                                                :
                                                                <p className="text-muted m-b-30 font-14">{this.state.user.phoneNumbers}</p>
                                                        }
                                                    </div>
                                                    <div >
                                                        {
                                                            this.state.isEditMode ?
                                                                <Button onClick={(e) => this.editUser(e)} type="success" color='primary' block>Done</Button>
                                                                :
                                                                <>
                                                                    <Button onClick={(e) => this.editMode(e)} color='primary' block>Edit</Button>
                                                                    <Button data-toggle="modal" data-target="#viewUser" onClick={() => this.deleteUser()} color='danger' block>Delete</Button>
                                                                </>

                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AUX>
        );
    }
}

const mapStatetoProps = state => {
    return {
        users: state.user.users,
        residence: state.residence,
        company: state.company
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ deleteUser, getUsers, createUser, editUser }, dispatch)
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Users));