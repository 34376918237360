import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { } from '../../../store/actions/admin'
import { firestore } from '../../../config/firebase'
import uuid from 'uuid'

class Contact extends Component {

    state = {
        name: '',
        email: '',
        subject: '',
        message: ''
    }

    componentDidMount = async () => {
        try {
            // await this.props.getUsers()
        } catch (e) {
            console.log(e.message)
        }
    }

    send = async (e) => {
        try {
            e.preventDefault()

            if (!this.state.message) {
                return
            }

            let newRequest = {
                id: uuid.v4(),
                date: new Date().getTime(),
                ...this.state
            }

            await firestore.collection('support').doc(newRequest.id).set(newRequest)

            this.setState({
                name: '',
                email: '',
                subject: '',
                message: ''
            })

        } catch (e) {
            console.log(e.message)
        }
    }

    render() {
        return (
            <AUX>

                <div className="page-content-wrapper">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="text-center">
                                                    <h5>Have any questions?</h5>
                                                    <p className="text-muted">It is a long established fact that a reader will be of a page when looking at its layout.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row m-t-40">
                                            <div className="col-md-2"></div>
                                            <div className="col-md-8">
                                                <form className="form-custom">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="sr-only" for="username">Name</label>
                                                                <input onChange={(event) => this.setState({ name: event.target.value })} value={this.state.name} type="text" className="form-control" id="username" placeholder="Your Name" required="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="sr-only" for="useremail">Email address</label>
                                                                <input onChange={(event) => this.setState({ email: event.target.value })} value={this.state.email} type="email" className="form-control" id="useremail" placeholder="Your Email" required="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label className="sr-only" for="usersubject">Subject</label>
                                                                <input onChange={(event) => this.setState({ subject: event.target.value })} value={this.state.subject} type="text" className="form-control" id="usersubject" placeholder="Subject" required="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <textarea onChange={(event) => this.setState({ message: event.target.value })} value={this.state.message} className="form-control" rows="5" placeholder="Your Message...."></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 text-center">
                                                            <button onClick={(e) => this.send(e)} type="button" className="btn btn-primary waves-effect waves-light">Send Message</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="col-md-2"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AUX>
        );
    }
}

const mapStatetoProps = state => {
    return {
        state: state.admin
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch)
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Contact));