import { firestore } from '../../config/firebase'
import { database } from '../../config/firebase'
import { orderBy } from 'lodash'
import uuid from 'uuid'

export const updatePanic = (newPanic) => {
    return async (dispatch, getState) => {
        try {

            console.log(newPanic)
            let { user, panic, company, activity, admin } = getState()

            const updatePanic = {
                ...newPanic,
            }

            let newPanics = panic.panics.map(item => {
                if (item.id === newPanic.id) {
                    item = updatePanic
                } return item
            })

            dispatch({ type: 'UPDATE_PANICS', payload: orderBy(newPanics, 'date', 'desc') })

            await firestore.collection('panic').doc(newPanic.id).update(updatePanic)

            let newActivity = {
                id: uuid.v4(),
                panicId: newPanic.id,
                actorUid: admin.uid,
                actorPhoto: admin.photo,
                actorName: admin.firstname + ' ' + admin.lastname,
                ownerUid: 'ALL',
                ownerName: newPanic.user.firstname + ' ' + newPanic.user.lastname,
                ownerPhoto: newPanic.user.photo.remoteUrl,
                photo: admin.photo,
                company: newPanic.company,
                date: new Date().getTime(),
                activity: 'UPDATE-PANIC',
            }

            activity.activities.push(newActivity)
            dispatch({ type: 'UPDATE_ACTIVITIES', payload: orderBy(activity.activities, 'date', 'desc') })
            await firestore.collection('activities').doc(newActivity.id).set(newActivity)

            // dispatch(notifyAll(company.name, newPanic.title, { panicId: newPanic.id }))

            database().ref('updatePanic').push(newPanic.id)

            return true
        } catch (e) {
            alert(e.message)
            console.log(e)
            return false
        }
    }
}

export const getPanics = () => {
    return async (dispatch, getState) => {
        try {
            let { uid } = getState().user
            let resolvedItems = []

            const query = await firestore.collection('panic').where('isActive', '==', true).get()
            query.forEach(async (item) => {
                resolvedItems.push(item.data())
            })

            dispatch({ type: 'UPDATE_PANICS', payload: orderBy(resolvedItems, 'date', 'desc') })

        } catch (e) {
            alert(e.message)
        }
    }
}