import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom';
import { getAlerts, editAlert, deleteAlert } from '../../../store/actions/alert'
import { Button, FormText } from 'reactstrap';
import { storage } from 'firebase'
import uuid from 'uuid'
import moment from 'moment'
import ReactAudioPlayer from 'react-audio-player';

class Incidences extends Component {

    state = {
        alert: {
            category: '',
            description: '',
            gallery: { localUrl: [], remoteUrl: [] },
            documents: { localUrl: [], remoteUrl: [], name: [] },
            voicenote: { localUrl: '', remoteUrl: '' },
            residence: '',
            unit: '',
            updates: [],
            messages: []
        },
        comment: '',
        errorMessage: '',
        isEditMode: false,
        itemCount: 16,
        category: 'All',
        status: 'All',
        residenceFilter: 'All',
        residences: []
    }

    componentDidMount = async () => {
        try {
            await this.props.getAlerts()
            let residences = []
            this.props.residence.residences.forEach(item => {
                residences.push(item.name)
            })
            this.setState({ residences })
        } catch (e) {
            console.log(e.message)
        }
    }

    dataValidation = () => {
        if (!this.state.alert.category) {
            this.setState({ errorMessage: 'Missing Category' })
            return false
        }
        else if (!this.state.alert.description) {
            this.setState({ errorMessage: 'Missing Description' })
            return false
        }
        else {
            this.setState({ errorMessage: '' })
            return true
        }
    }

    editAlert = async (e) => {
        try {
            e.preventDefault()

            if (!this.dataValidation()) {
                return
            }

            let residence = this.props.residence.residences.find(r => r.id === this.state.alert.residenceUid)

            let newUpdates = this.state.alert.updates
            newUpdates.push({
                id: uuid.v4(),
                name: residence.name,
                photo: residence.photo,
                date: new Date().getTime(),
                comment: this.state.comment || 'Status changed to ' + this.state.alert.status,
                status: this.state.alert.status,
                event: this.state.comment ? 'Comment-Update' : 'Status-Update'
            })

            let newMessages = this.state.alert.messages
            newMessages.push({
                _id: uuid.v4(),
                createdAt: new Date().getTime(),
                text: this.state.comment,
                user: {
                    _id: this.props.admin.uid,
                    name: residence.name,
                    avatar: residence.photo,
                }
            })

            this.setState({ isEditMode: false, alert: { ...this.state.alert, messages: newMessages } })

            if (await this.props.editAlert(this.state.alert)) {

            } else {

            }
        } catch (e) {
            alert(e.message)
        }
    }

    deleteAlert = async (e) => {
        try {
            if (await this.props.deleteAlert(this.state.alert)) {
                this.setState({})
            } else {

            }
        } catch (e) {
            console.log(e.message)
        }
    }

    editMode = async (e) => {
        try {
            e.preventDefault()
            this.setState({ isEditMode: true })
        } catch (e) {
            console.log(e.message)
        }
    }

    handleDocuments = e => {

        if (e.target.files[0]) {
            const doc = e.target.files[0];
            this.uploadDocument(doc)
        }
    }

    uploadDocument = async (doc) => {
        try {

            const uploadTask = storage().ref(`documents/${doc.name}`).put(doc)

            uploadTask.on(
                "state_changed",
                snapshot => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    this.setState({ progress });
                },
                error => {
                    console.log(error);
                },
                async () => {
                    let url = await storage().ref("documents").child(doc.name).getDownloadURL()
                    let updatedDocument = this.state.alert.documents
                    updatedDocument.remoteUrl.push(url)
                    updatedDocument.name.push(doc.name)
                    this.setState({ alert: { ...this.state.alert, documents: updatedDocument } });
                }
            );
        } catch (e) {
            console.log(e)
        }
    }

    handleImages = e => {

        if (e.target.files[0]) {
            const image = e.target.files[0];
            this.uploadImage(image)
        }
    }

    uploadImage = async (image) => {
        try {

            const uploadTask = storage().ref(`images/${image.name}`).put(image);
            uploadTask.on(
                "state_changed",
                snapshot => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    this.setState({ progress });
                },
                error => {
                    console.log(error);
                },
                async () => {
                    let url = await storage().ref("images").child(image.name).getDownloadURL()
                    let updatedgallery = this.state.alert.gallery
                    updatedgallery.remoteUrl.push(url)
                    this.setState({ alert: { ...this.state.alert, gallery: updatedgallery } });
                }
            );
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        const d = this.state.category === 'All' ? this.props.alert.alerts.slice(0, this.state.itemCount) : this.props.alert.alerts.slice(0, this.state.itemCount).filter(i => i.category === this.state.category)
        const d2 = this.state.status === 'All' ? d : d.filter(s => s.status === this.state.status)
        const data = this.state.residenceFilter === 'All' ? d2 : d2.filter(r => r.residence === this.state.residenceFilter)
        const residence = this.props.residence.residences.find(r => r.id === this.state.alert.residenceUid)
        // alert(JSON.stringify( this.state.alert.gallery.remoteUrl))
        return (
            <AUX>
                <div className="page-content-wrapper">
                    <div className="container-fluid">

                        <div className='row'>
                            <div className="col-3">
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Residence</label>
                                    <div className="col-sm-7">
                                        <select options={this.state.residences} onChange={(event) => this.setState({ residenceFilter: event.target.value })} value={this.state.residenceFilter} className="form-control select2">
                                            <option>All</option>
                                            {
                                                this.state.residences.map(item => (
                                                    <option>{item}</option>
                                                ))
                                            }

                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Category</label>
                                    <div className="col-sm-7">
                                        <select onChange={(event) => this.setState({ category: event.target.value })} value={this.state.category} className="form-control select2">
                                            <option>All</option>
                                            <option>Security</option>
                                            <option>Maintainance</option>
                                            <option>Administrative</option>
                                            <option>Other</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Status</label>
                                    <div className="col-sm-7">
                                        <select onChange={(event) => this.setState({ status: event.target.value })} value={this.state.status} className="form-control select2">
                                            <option>All</option>
                                            <option>Open</option>
                                            <option>Pending</option>
                                            <option>Closed</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Show Items</label>
                                    <div className="col-sm-7">
                                        <select onChange={(event) => this.setState({ itemCount: event.target.value })} value={this.state.itemCount} className="form-control select2">
                                            <option>16</option>
                                            <option>32</option>
                                            <option>48</option>
                                            <option>54</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            {
                                                data.map(item => (
                                                    <div style={{ textAlign: 'center', marginBottom: 20 }} className="col-md-2">
                                                        {/* <div style={{ backgroundColor: 'red' }} className="product-list-box"> */}
                                                        <a data-toggle="modal" data-target="#viewAlert" style={{ cursor: 'pointer', borderRadius: 10 }} onClick={() => this.setState({ alert: item })}>
                                                            {/* <div className='row'> */}
                                                            <div style={{ textAlign: 'center', alignSelf: 'center' }} className="detail col-sm-12">
                                                                {
                                                                    item.category === 'Security' ? <i style={{ fontSize: 100, textAlign: 'center', alignSelf: 'center' }} className="mdi mdi-security noti-icon"></i> : null
                                                                }
                                                                {
                                                                    item.category === 'Maintainance' ? <i style={{ fontSize: 100, textAlign: 'center', alignSelf: 'center' }} className="ion-ios7-bookmarks noti-icon"></i> : null
                                                                }
                                                                {
                                                                    item.category === 'Administrative' ? <i style={{ fontSize: 100 }} className="ion-calendar noti-icon"></i> : null
                                                                }
                                                                {
                                                                    item.category === 'Other' ? <i style={{ fontSize: 100 }} className="ion-nuclear noti-icon"></i> : null
                                                                }

                                                            </div>
                                                            <div style={{ textAlign: 'center' }} className="detail col-sm-12">
                                                                <FormText style={{ fontSize: 18, fontWeight: 'bold' }} className="font-16">{item.firstname} {item.lastname}</FormText>
                                                                <FormText style={{ fontSize: 14 }} className="font-14">{item.category}</FormText>
                                                                <FormText style={{ fontSize: 12 }} className="font-12">{item.status}</FormText>
                                                                <FormText style={{ fontSize: 10 }} className="font-10">{moment(item.date).format('llll')}</FormText>
                                                            </div>
                                                            {/* </div> */}
                                                        </a>
                                                    </div>
                                                    // </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >


                <div id="viewAlert" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div style={{ margin: 'auto', paddingTop: 30, paddingBottom: 30, width: '80%' }} className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">View Incident</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body">
                                {
                                    this.state.errorMessage ?
                                        <p style={{ color: 'red' }} className="text-muted m-b-30 font-14 alert-danger">{this.state.errorMessage}</p> : null
                                }

                                <div className="card-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label className="control-label">Status</label>
                                                    {
                                                        this.state.isEditMode ?
                                                            <select onChange={(event) => this.setState({ alert: { ...this.state.alert, status: event.target.value } })} value={this.state.alert.status} className="form-control select2">
                                                                <option value="Open">Open</option>
                                                                <option value="Pending">Pending</option>
                                                                <option value="Closed">Closed</option>
                                                            </select> :
                                                            <p className="text-muted m-b-30 font-14">{this.state.alert.status}</p>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label">Category</label>
                                                    {
                                                        this.state.isEditMode ?
                                                            <select onChange={(event) => this.setState({ alert: { ...this.state.alert, category: event.target.value } })} value={this.state.alert.category} className="form-control select2">
                                                                <option>Select</option>
                                                                <option value="General">General</option>
                                                                <option value="Security">Security</option>
                                                                <option value="Administrative">Administrative</option>
                                                                <option value="Promotion">Promotion</option>
                                                            </select> :
                                                            <p className="text-muted m-b-30 font-14">{this.state.alert.category}</p>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label for="metadescription">Residence</label>
                                                    <p className="text-muted m-b-30 font-14">{this.state.alert.residence}</p>
                                                </div>
                                                <div className="form-group">
                                                    <label for="metadescription">Unit</label>
                                                    <p className="text-muted m-b-30 font-14">{this.state.alert.unit}</p>
                                                </div>
                                                <div className="form-group">
                                                    <label for="metadescription">Description</label>
                                                    <p className="text-muted m-b-30 font-14">{this.state.alert.description}</p>
                                                </div>
                                                <div className="form-group">
                                                    {
                                                        this.state.alert.voicenote.remoteUrl.length > 0 ?
                                                            <>
                                                                <label for="metadescription">Voicenote</label>
                                                                <div className='row'>
                                                                    {
                                                                        <ReactAudioPlayer
                                                                            src={this.state.alert.voicenote.remoteUrl}
                                                                            controls
                                                                        />
                                                                    }
                                                                </div>
                                                            </> : null
                                                    }

                                                </div>
                                                <div className="form-group">
                                                    {
                                                        this.state.alert.gallery.remoteUrl.length && this.state.alert.gallery.remoteUrl[0].length > 0 ?
                                                            <>
                                                                <label for="metadescription">Images</label>
                                                                <div className='row'>
                                                                    {
                                                                        this.state.alert.gallery.remoteUrl.map(url => (
                                                                            url ?
                                                                                <div className='col-sm-4'>
                                                                                    < img style={{ height: 300, width: 300, margin: 8 }} src={url} alt="product img" className="img-fluid" />
                                                                                </div> : null
                                                                        ))}
                                                                </div>
                                                            </>
                                                            : null

                                                    }

                                                    {
                                                        this.state.isEditMode ?
                                                            <div className="custom-file">
                                                                <input
                                                                    onChange={this.handleImages}
                                                                    type="file"
                                                                    className="custom-file-input"
                                                                    accept="image/x-png,image/gif,image/jpeg"
                                                                    id="inputGroupFile01"
                                                                    aria-describedby="inputGroupFileAddon01" />
                                                                <label style={{ width: '100%', textAlign: 'center' }} color="secondary" className="btn btn-secondary" htmlFor="inputGroupFile01">
                                                                    Upload Image
                                                         </label>
                                                            </div> : null
                                                    }

                                                </div>
                                                {
                                                    this.state.alert.documents.remoteUrl.length && this.state.alert.documents.remoteUrl[0] > 0 ?
                                                        <div className="form-group">
                                                            <label for="metadescription">Documents</label>
                                                            <div className='row'>
                                                                {
                                                                    this.state.alert.documents.remoteUrl.map((url, index) => (
                                                                        <div style={{ textAlign: 'center' }} className='col-sm-4 text-center'>
                                                                            <img style={{ height: 100, width: 100, margin: 8, textAlign: 'center' }} src={"assets/images/doc.png"} alt="product img" className="img-fluid" />
                                                                            <FormText style={{ textAlign: 'center' }}>{this.state.alert.documents.name[index]}</FormText>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                            {
                                                                this.state.isEditMode ?
                                                                    <div className="custom-file">
                                                                        <input
                                                                            onChange={this.handleDocuments}
                                                                            accept=".pdf"
                                                                            type="file"
                                                                            className="custom-file-input"
                                                                            id="inputGroupFile02"
                                                                            aria-describedby="inputGroupFileAddon01" />
                                                                        <label style={{ width: '100%', textAlign: 'center' }} color="secondary" className="btn btn-secondary" htmlFor="inputGroupFile02">
                                                                            Upload Documents
                                                        </label>
                                                                    </div> : null
                                                            }

                                                        </div> : null
                                                }

                                                {
                                                    this.state.alert.messages && this.state.alert.messages.length > 0 ?
                                                        <div className="form-group">
                                                            <label for="metadescription">Updates</label>
                                                            {this.state.alert.messages.sort((a, b) => b.createdAt - a.createdAt).map(item => (
                                                                <>
                                                                    {
                                                                        residence.name === item.user.name ?

                                                                            <div style={{ margin: 25, alignItems: 'center', justifyContent: 'center' }} className='row'>
                                                                                <img style={{ width: 50, height: 50, justifyContent: 'center' }} src={item.user.avatar ? item.user.avatar : 'assets/images/users/avatar.jpg'} className="img-fluid rounded-circle" alt="work-thumbnail" />
                                                                                <div className="detail col-sm-9">
                                                                                    <FormText style={{ fontSize: 18, fontWeight: 'bold' }} className="font-16">{item.user.name}</FormText>
                                                                                    {
                                                                                        item.text ? <FormText style={{ fontSize: 14 }} className="font-13">{item.text}</FormText> : null
                                                                                    }
                                                                                    <FormText style={{ fontSize: 12 }} className="font-13">{moment(item.createdAt).format('llll')}</FormText>
                                                                                </div>
                                                                            </div> :
                                                                            <div style={{ margin: 15, alignItems: 'center', justifyContent: 'center' }} className='row'>
                                                                                <div style={{ alignSelf: 'flex-center' }} className="detail col-sm-7">
                                                                                    {
                                                                                        item.image ?

                                                                                            <img style={{ width: '100%', justifyContent: 'center', borderRadius: 5 }} src={item.image} className="img-fluid rounded-rounded" alt="work-thumbnail" />

                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        item.audio ?

                                                                                            <ReactAudioPlayer
                                                                                                src={item.audio}

                                                                                                controls
                                                                                            />
                                                                                            : null
                                                                                    }
                                                                                </div>
                                                                                <div style={{ alignSelf: 'flex-center', alignItems: 'center', justifyContent: 'center', textAlign: 'end' }} className="detail col-sm-3">
                                                                                    <FormText style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'end' }} className="font-16">{item.user.name}</FormText>
                                                                                    {
                                                                                        item.text ? <FormText style={{ fontSize: 14 }} className="font-13">{item.text}</FormText> : null
                                                                                    }
                                                                                    <FormText style={{ fontSize: 12, textAlign: 'end' }} className="font-13">{moment(item.createdAt).format('llll')}</FormText>
                                                                                </div>
                                                                                <div style={{ alignSelf: 'flex-center' }} className="detail col-sm-2">
                                                                                    <img style={{ width: 50, height: 50, justifyContent: 'center' }} src={item.user.avatar ? item.user.avatar : 'assets/images/users/avatar.jpg'} className="img-fluid rounded-circle" alt="work-thumbnail" />
                                                                                </div>

                                                                            </div>
                                                                    }
                                                                </>
                                                            ))}
                                                        </div> : null
                                                }
                                                {
                                                    this.state.isEditMode ?
                                                        <div className="form-group">
                                                            <label for="metacomment">Comment</label>
                                                            <textarea onChange={(event) => this.setState({ comment: event.target.value })} value={this.state.comment} className="form-control" id="comment" rows="2"></textarea>
                                                        </div> : null
                                                }
                                                <div >
                                                    {
                                                        this.state.isEditMode ?
                                                            <div style={{ margin: 15 }}>
                                                                <Button onClick={(e) => this.editAlert(e)} type="success" color='primary' block>Done</Button>
                                                            </div> :
                                                            <div style={{ margin: 15 }}>
                                                                <Button onClick={(e) => this.editMode(e)} color='primary' block>Update</Button>
                                                                <Button data-toggle="modal" data-target="#viewAnnoucment" onClick={() => this.deleteAlert()} color='danger' block>Delete</Button>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AUX >
        );
    }
}

const mapStatetoProps = state => {
    return {
        admin: state.admin,
        alert: state.alert,
        company: state.company,
        residence: state.residence
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getAlerts, editAlert, deleteAlert }, dispatch)
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Incidences));