import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Route, Switch } from 'react-router-dom';
import Home from '../MainContent/Ghea/Home';
import Residents from '../MainContent/Ghea/Residents';
import Branding from '../MainContent/Ghea/Branding';
import Users from '../MainContent/Ghea/Users';
import Annoucements from '../MainContent/Ghea/Annoucements';
import Incidences from '../MainContent/Ghea/Incidences';
import Activities from '../MainContent/Ghea/Activities';
import Help from '../MainContent/Ghea/Help';
import Contact from '../MainContent/Ghea/Contact';
import Profile from '../MainContent/Ghea/Profile';
import Login from '../MainContent/Ghea/Login';
import Policy from '../MainContent/Ghea/Policy';
// import Register from '../MainContent/Ghea/Register';
import { getAlerts } from '../../store/actions/alert'
import { getActivity } from '../../store/actions/activity'
import { sync } from '../../store/actions/index'
import { database } from '../../config/firebase'
import firebase from 'firebase'

class mainbuilder extends Component {

    state = {
        isAuthenticated: false
    }

    componentDidMount = async () => {
        this.props.sync()
        database().ref('updateTick').on('child_added', async (snapshot) => {
            await this.props.getAlerts()
            await this.props.getActivity()
            // alert('update has happened')
        })

        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({ isAuthenticated: true })
            } else {
                this.setState({ isAuthenticated: false })
            }
        })
    }

    render() {
        return (

            this.props.admin.isAuthenticated ?
                <Switch >
                     <Route path="/policy" component={Policy} />
                    <Route path="/residents" component={Residents} />
                    <Route path="/branding" component={Branding} />
                    <Route path="/users" component={Users} />
                    <Route path="/annoucements" component={Annoucements} />
                    <Route path="/Incidences" component={Incidences} />
                    <Route path="/activities" component={Activities} />
                    <Route path="/help" component={Help} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/profile" component={Profile} />
                    <Route path="/home" component={Home} />
                    <Route path="/" component={Home} />
                </Switch >
                :
                <Switch>
                    <Route path="/policy" component={Policy} />
                    <Route path="/login" component={Login} />
                    <Route path="/" component={Login} />
                </Switch>


        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getAlerts, getActivity, sync }, dispatch)
}

const mapStatetoProps = state => {
    return {
        admin: state.admin
    };
}

export default connect(mapStatetoProps, mapDispatchToProps)(mainbuilder);