import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom';
import { getResidences, createResidence, editResidence, deleteResidence } from '../../../store/actions/residence'
import { Button, FormText } from 'reactstrap';
import { storage } from 'firebase'

class Residents extends Component {

    state = {
        residence: {
            name: '',
            description: '',
            address: '',
            city: '',
            province: '',
            postalCode: '',
            guidelines: '',
            photo: '',
        },
        errorMessage: '',
        isEditMode: false,
        itemCount: 16
    }

    componentDidMount = async () => {
        try {
            await this.props.getResidences()
        } catch (e) {
            console.log(e.message)
        }
    }

    dataValidation = () => {
        if (!this.state.residence.name) {
            this.setState({ errorMessage: 'Missing Name' })
            return false
        }
        else if (!this.state.residence.address) {
            this.setState({ errorMessage: 'Missing Address' })
            return false
        }
        else if (!this.state.residence.city) {
            this.setState({ errorMessage: 'Missing City' })
            return false
        }
        else if (!this.state.residence.province) {
            this.setState({ errorMessage: 'Missing Province' })
            return false
        }
        else if (!this.state.residence.postalCode) {
            this.setState({ errorMessage: 'Missing Postal Code' })
            return false
        }
        else if (!this.state.residence.description) {
            this.setState({ errorMessage: 'Missing Description' })
            return false
        }
        else if (!this.state.residence.photo) {
            this.setState({ errorMessage: 'Missing Photo' })
            return false
        }
        else {
            this.setState({ errorMessage: '' })
            return true
        }
    }

    createResidence = async (e) => {
        try {
            e.preventDefault()
            if (!this.dataValidation()) {
                return
            }

            if (await this.props.createResidence(this.state.residence)) {

            } else {

            }
        } catch (e) {
            console.log(e.message)
        }
    }

    editResidence = async (e) => {
        try {
            e.preventDefault()

            if (!this.dataValidation()) {
                return
            }

            this.setState({ isEditMode: false })

            if (await this.props.editResidence(this.state.residence)) {

            } else {

            }
        } catch (e) {
            console.log(e.message)
        }
    }

    deleteResicence = async (e) => {
        try {
            if (await this.props.deleteResidence(this.state.residence)) {
                this.setState({})
            } else {

            }
        } catch (e) {
            console.log(e.message)
        }
    }

    editMode = async (e) => {
        try {
            e.preventDefault()
            this.setState({ isEditMode: true })
        } catch (e) {
            console.log(e.message)
        }
    }

    handleImages = e => {

        if (e.target.files[0]) {
            const image = e.target.files[0];
            this.uploadImage(image)
        }
    }

    uploadImage = async (image) => {
        try {

            const uploadTask = storage().ref(`images/${image.name}`).put(image);
            uploadTask.on(
                "state_changed",
                snapshot => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    this.setState({ progress });
                },
                error => {
                    console.log(error);
                },
                async () => {
                    let url = await storage().ref("images").child(image.name).getDownloadURL()
                    this.setState({ residence: { ...this.state.residence, photo: url } });
                }
            );
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        const data = this.props.residences.slice(0, this.state.itemCount)
        return (
            <AUX>
                <div className="page-content-wrapper">
                    <div className="container-fluid">

                        <div className='row' style={{}}>

                            <div className="col-3">
                                <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-2 col-form-label">Search</label>
                                    <div className="col-sm-8">
                                        <input className="form-control" type="text" placeholder="search" id="example-text-input" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-3">
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Show Items</label>
                                    <div className="col-sm-7">
                                        <select onChange={(event) => this.setState({ itemCount: event.target.value })} value={this.state.itemCount} className="form-control select2">
                                            <option>16</option>
                                            <option>32</option>
                                            <option>48</option>
                                            <option>54</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">

                            </div>
                            <div className="col-3">
                                <div className="form-group row">
                                    <div className="col-sm-12">

                                        <button data-toggle="modal" className='btn btn-primary btn-block' onClick={() => this.setState({
                                            residence: {
                                                name: '',
                                                description: '',
                                                address: '',
                                                city: '',
                                                province: '',
                                                postalCode: '',
                                                guidelines: '',
                                                photo: '',
                                            }
                                        })} data-target="#myModal">Create Residence</button>

                                        <div id="myModal" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                                            <div style={{ margin: 'auto', paddingTop: 30, paddingBottom: 30, width: '80%' }} className="modal-dialog modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title mt-0" id="myModalLabel">Create Residence</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                                    </div>
                                                    <div className="modal-body">
                                                        {
                                                            this.state.errorMessage ?
                                                                <p style={{ color: 'red' }} className="text-muted m-b-30 font-14 alert-danger">{this.state.errorMessage}</p> : null
                                                        }

                                                        <div className="card-body">
                                                            <form>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <div className="form-group">
                                                                            <label for="metatitle">Name</label>
                                                                            <input onChange={(event) => this.setState({ residence: { ...this.state.residence, name: event.target.value } })} value={this.state.residence.name} id="metatitle" name="productname" type="text" className="form-control" />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label for="metadescription">Address</label>
                                                                            <input onChange={(event) => this.setState({ residence: { ...this.state.residence, address: event.target.value } })} value={this.state.residence.address} className="form-control" id="metadescription" rows="5"></input>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label for="metadescription">City</label>
                                                                            <input onChange={(event) => this.setState({ residence: { ...this.state.residence, city: event.target.value } })} value={this.state.residence.city} className="form-control" id="metadescription" rows="5"></input>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label for="metadescription">Province</label>
                                                                            <input onChange={(event) => this.setState({ residence: { ...this.state.residence, province: event.target.value } })} value={this.state.residence.province} className="form-control" id="metadescription" rows="5"></input>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label for="metadescription">Postal Code</label>
                                                                            <input onChange={(event) => this.setState({ residence: { ...this.state.residence, postalCode: event.target.value } })} value={this.state.residence.postalCode} className="form-control" id="metadescription" rows="5"></input>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label for="metadescription">Description</label>
                                                                            <textarea onChange={(event) => this.setState({ residence: { ...this.state.residence, description: event.target.value } })} value={this.state.residence.description} className="form-control" id="metadescription" rows="5"></textarea>
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label for="metadescription">Photo</label>
                                                                            <div className='row'>
                                                                                <div style={{ textAlign: 'center' }} className='col-12'>
                                                                                    {
                                                                                        <p>
                                                                                            <img style={{ height: 250, width: 250, objectFit: 'contain', marginBottom: 5, textAlign: 'center' }} alt="200x200" src={this.state.residence.photo ? this.state.residence.photo : 'assets/images/users/avatar.jpg'} data-holder-rendered="true" />
                                                                                        </p>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="custom-file">
                                                                                <input
                                                                                    onChange={this.handleImages}
                                                                                    type="file"
                                                                                    className="custom-file-input"
                                                                                    accept="image/x-png,image/gif,image/jpeg"
                                                                                    id="inputGroupFile01"
                                                                                    aria-describedby="inputGroupFileAddon01" />
                                                                                <label style={{ width: '100%', textAlign: 'center' }} color="secondary" className="btn btn-secondary" htmlFor="inputGroupFile01">
                                                                                    Upload Image
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div >
                                                                            <Button data-target="#myModal" data-toggle="modal" onClick={(e) => this.createResidence(e)} type="primary" color='primary' className="btn btn-success waves-effect waves-light" block>Submit</Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div style={{ padding: 50 }} className="row">
                                            {
                                                data.map(item => (
                                                    <div style={{ marginBottom: 30 }} className="col-lg-3">
                                                        <a data-toggle="modal" data-target="#viewDetail" style={{ cursor: 'pointer' }} onClick={() => this.setState({ residence: item })}>
                                                            <div className='row'>
                                                                <div className="col-12">
                                                                    <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={item.photo} className="img-fluid" alt="work-thumbnail" />
                                                                    <div className="detail col-sm-12">
                                                                        <FormText style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'center' }} className="font-16">{item.name}</FormText>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>

                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="viewDetail" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div style={{ margin: 'auto', paddingTop: 30, paddingBottom: 30, width: '80%' }} className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">View Residence</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body">
                                {
                                    this.state.errorMessage ?
                                        <p style={{ color: 'red' }} className="text-muted m-b-30 font-14 alert-danger">{this.state.errorMessage}</p> : null
                                }

                                <div className="card-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label for="metatitle">Name</label>
                                                    {
                                                        this.state.isEditMode ?
                                                            <input onChange={(event) => this.setState({ residence: { ...this.state.residence, name: event.target.value } })} value={this.state.residence.name} id="metatitle" name="name" type="text" className="form-control" />
                                                            :
                                                            <p className="text-muted m-b-30 font-14">{this.state.residence.name}</p>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label for="metadescription">Address</label>
                                                    {
                                                        this.state.isEditMode ?
                                                            <input onChange={(event) => this.setState({ residence: { ...this.state.residence, address: event.target.value } })} value={this.state.residence.address} className="form-control" id="metadescription" rows="5"></input>
                                                            :
                                                            <p className="text-muted m-b-30 font-14">{this.state.residence.address}</p>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label for="metadescription">City</label>
                                                    {
                                                        this.state.isEditMode ?
                                                            <input onChange={(event) => this.setState({ residence: { ...this.state.residence, city: event.target.value } })} value={this.state.residence.city} className="form-control" id="metadescription" rows="5"></input>
                                                            :
                                                            <p className="text-muted m-b-30 font-14">{this.state.residence.city}</p>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label for="metadescription">Province</label>
                                                    {
                                                        this.state.isEditMode ?
                                                            <input onChange={(event) => this.setState({ residence: { ...this.state.residence, province: event.target.value } })} value={this.state.residence.province} className="form-control" id="metadescription" rows="5"></input>
                                                            :
                                                            <p className="text-muted m-b-30 font-14">{this.state.residence.province}</p>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label for="metadescription">Postal Code</label>
                                                    {
                                                        this.state.isEditMode ?
                                                            <input onChange={(event) => this.setState({ residence: { ...this.state.residence, postalCode: event.target.value } })} value={this.state.residence.postalCode} className="form-control" id="metadescription" rows="5"></input>
                                                            :
                                                            <p className="text-muted m-b-30 font-14">{this.state.residence.postalCode}</p>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label for="metadescription">Description</label>
                                                    {
                                                        this.state.isEditMode ?
                                                            <textarea onChange={(event) => this.setState({ residence: { ...this.state.residence, description: event.target.value } })} value={this.state.residence.description} className="form-control" id="metadescription" rows="5"></textarea>
                                                            :
                                                            <p className="text-muted m-b-30 font-14">{this.state.residence.description}</p>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label for="metadescription">Photo</label>
                                                    <div className='row'>
                                                        {
                                                            <p>
                                                                <img style={{ height: 100, width: 100, objectFit: 'contain', marginBottom: 5 }} alt="200x200" src={this.state.residence.photo ? this.state.residence.photo : 'assets/images/users/avatar.jpg'} data-holder-rendered="true" />
                                                            </p>
                                                        }
                                                    </div>
                                                    {
                                                        this.state.isEditMode ?
                                                            <div className="custom-file">
                                                                <input
                                                                    onChange={this.handleImages}
                                                                    type="file"
                                                                    className="custom-file-input"
                                                                    accept="image/x-png,image/gif,image/jpeg"
                                                                    id="inputGroupFile01"
                                                                    aria-describedby="inputGroupFileAddon01" />
                                                                <label style={{ width: '100%', textAlign: 'center' }} color="secondary" className="btn btn-secondary" htmlFor="inputGroupFile01">
                                                                    Upload Image
                                                         </label>
                                                            </div> : null
                                                    }

                                                </div>
                                                <div >
                                                    {
                                                        this.state.isEditMode ?
                                                            <Button onClick={(e) => this.editResidence(e)} type="success" color='primary' block>Done</Button>
                                                            :
                                                            <>
                                                                <Button onClick={(e) => this.editMode(e)} color='primary' block>Edit</Button>
                                                                <Button data-toggle="modal" data-target="#viewDetail" onClick={() => this.deleteResicence()} color='danger' block>Delete</Button>
                                                            </>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </AUX >
        );
    }
}

const mapStatetoProps = state => {
    return {
        admin: state.admin,
        residences: state.residence.residences
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getResidences, createResidence, editResidence, deleteResidence }, dispatch)
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Residents));