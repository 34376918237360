import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { signout } from '../../store/actions/admin'
import moment from 'moment'

class header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpenprofile: false,
            dropdownOpenbadge: false,
            now_route: ""
        };

        this.toggleprofile = this.toggleprofile.bind(this);
        this.togglebadge = this.togglebadge.bind(this);
    }

    toggleprofile() {
        this.setState(prevState => ({
            dropdownOpenprofile: !prevState.dropdownOpenprofile
        }));
    }
    togglebadge() {
        this.setState(prevState => ({
            dropdownOpenbadge: !prevState.dropdownOpenbadge
        }));
    }

    componentDidMount() {
        this.setState({ now_route: this.props.location.pathname })
    }

    render() {
        const activities = this.props.activity.activities
        return (
            <div className="topbar">
                <nav className="navbar-custom">

                    <ul className="list-inline float-right mb-0">
                        <li className="list-inline-item dropdown notification-list">
                            <Dropdown isOpen={this.state.dropdownOpenbadge} toggle={this.togglebadge}>
                                <DropdownToggle className="nav-link dropdown-toggle droptest arrow-none waves-effect" tag="a">
                                    <i className="ion-ios7-bell noti-icon"></i>
                                    {/* <span className="badge badge-pill badge-danger noti-icon-badge">3</span> */}
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu dropdown-menu-right dropdown-menu-lg">
                                    <h6 className="dropdown-item-text">
                                        Notifications ({activities.length})
                                        </h6>

                                    <DropdownItem >
                                        <Scrollbars style={{ height: "200px" }} className="notification-item-list" >
                                            {
                                                activities.splice(1, 5).map(item => (
                                                    <Link style={{}} id="ex" to="activities" className="dropdown-item notify-item active">
                                                        <div className="notify-icon bg-success"><i className="mdi mdi-bell"></i></div>
                                                        <p className="notify-details">{item.actorName}<span className="text-muted">{moment(item.date).format('llll')}</span></p>
                                                    </Link>
                                                ))
                                            }
                                        </Scrollbars>
                                        <Link id="ex" to="activities" className="dropdown-item text-center text-primary">
                                            View all <i className="fi-arrow-right"></i>
                                        </Link>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </li>
                        <li className="list-inline-item dropdown notification-list">
                            <Dropdown isOpen={this.state.dropdownOpenprofile} toggle={this.toggleprofile}>
                                <DropdownToggle className="nav-link dropdown-toggle droptest arrow-none waves-effect nav-user" tag="a">
                                    {/* <img style={{ objectFit: 'contain' }} src={this.props.admin.company.photo ? this.props.admin.company.photo : null} alt="user" className="rounded-circle" /> */}
                                    <span> {this.props.admin.firstname}       </span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => this.props.history.push('/profile')}><i className="mdi mdi-account-circle m-r-5"></i> Profile</DropdownItem>
                                    <DropdownItem onClick={() => this.props.signout()}><i className="mdi mdi-power text-danger"></i> Logout</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </li>
                    </ul>

                    <ul className="list-inline menu-left mb-0">
                        <li className="list-inline-item">
                            <button type="button" className="button-menu-mobile open-left waves-effect">
                                <i className="ion-navicon"></i>
                            </button>
                        </li>
                        <li className="hide-phone list-inline-item app-search">
                            <h3 className="page-title" id="now_routing"></h3>
                        </li>
                    </ul>
                    <div className="clearfix"></div>
                </nav>
            </div>

        );
    }
}

const mapStatetoProps = state => {
    return {
        admin: state.admin,
        activity: state.activity
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ signout }, dispatch)
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(header));