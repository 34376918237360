let initialState = {
    agents: [],
    users: [],
    uid: '',
    name: '',
    city: '',
    province: '',
    postalCode: '',
    description: '',
    address: '',
    guidelines: '',
    phoneNumber: '',
    photo: '',
    companyUid: '',
    residences: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_RESIDENCES':
            return { ...state, residences: action.payload }
        case 'UPDATE_USERS':
            return { ...state, users: action.payload }
        case 'UPDATE_AGENTS':
            return { ...state, agents: action.payload }
        case 'UPDATE_RESIDENCE':
            return action.payload;
        default:
            return state
    }
}