import { firestore } from '../../config/firebase'
import firebase from 'firebase'
import { getCompany } from './company'

export const login = (formData) => {
    return async (dispatch, getState) => {
        try {
            const response = await firebase.auth().signInWithEmailAndPassword(formData.email, formData.password)
            if (response) {
                const userQuery = await firestore.collection('admins').doc(response.user.uid).get()
                if (userQuery.exists) {
                    let user = userQuery.data()
                    user.isAuthenticated = true
                    user.usNewuser = false
                    dispatch({ type: 'UPDATE_ADMIN', payload: user })
                    await dispatch({ type: 'UPDATE_COMPANY', payload: user.company })
                } else {
                    let results = []
                    const companyQuery = await firestore.collection('companies').where('admins', 'array-contains', formData.email).get()

                    companyQuery.forEach(item => {
                        results.push(item.data())
                    })
                    let company = results[0]
                   
                    if (company) {

                        const newUser = {
                            uid: response.user.uid,
                            email: formData.email,
                            firstname: 'admin',
                            lastname: 'admin',
                            phoneNumber: '',
                            companyUid: company.uid,
                            photo: '',
                            role: 'A',
                            isVerified: false,
                            token: '',
                            isAuthenticated: true,
                            company: company,
                            isNewuser: true,
                        }

                        await firestore.collection('admins').doc(response.user.uid).set(newUser)
                        await dispatch({ type: 'UPDATE_COMPANY', payload: company })
                        await dispatch({ type: 'UPDATE_ADMIN', payload: newUser })
                        return true
                    } else {
                        return false
                    }
                }
            } else {
                return false
            }
        } catch (e) {
            console.log(e.message)
        }
    }
}

export const changePassword = (currentPassword, password) => {
    return async (dispatch, getState) => {
        try {
            if (password !== currentPassword) {
                let cred = await firebase.auth.EmailAuthProvider.credential(firebase.auth().currentUser.email, currentPassword)
                let result = await firebase.auth().currentUser.reauthenticateWithCredential(cred)
                if (result) {
                    firebase.auth().currentUser.updatePassword(password)
                    return true
                }
            } else {
                return false
            }

        } catch (e) {
            console.log(e.message)
        }
    }
}

export const editProfile = (user, company) => {
    return async (dispatch, getState) => {
        try {
            const { uid, email } = getState().admin

            if (email !== user.email) {
                var cred = await firebase.auth.EmailAuthProvider.credential(firebase.auth().currentUser.email, user.password)
                var result = await firebase.auth().currentUser.reauthenticateWithCredential(cred)
                if (result) {
                    firebase.auth().currentUser.updateEmail(user.email)
                }
            }

            user.isNewUser = false
            user.company = company
            await firestore.collection('admins').doc(uid).update(user)

            await firestore.collection('companies').doc(company.uid).update(company)

            const newUser = await firestore.collection('admins').doc(uid).get()

            if (newUser.data()) {
                await dispatch({ type: 'UPDATE_ADMIN', payload: newUser.data() })
                return true
            } else {
                return false
            }
        } catch (e) {
            console.log(e.message)
        }
    }
}

export const resetPassword = (email) => {
    return async (dispatch, getState) => {
        try {
            await firebase.auth().sendPasswordResetEmail(email)
            return true
        } catch (e) {
            console.log('System Error', e.message)
            return false
        }
    }
}

export const addAdminUser = (formData) => {
    return async (dispatch, getState) => {
        try {
            await firestore.collection('companies').doc('MmaNMcSfSSXt0xGhzovz').update({
                admins: firebase.firestore.FieldValue.arrayUnion(formData)
            })

            const com = await firestore.collection('companies').doc("MmaNMcSfSSXt0xGhzovz").get()

            console.log(com.data())
            return true
        } catch (e) {
            console.log('System Error', e.message)
            return false
        }
    }
}

export const signout = () => {
    return async (dispatch, getState) => {
        try {
            let initialState = {
                uid: '',
                firstname: '',
                lastname: '',
                email: '',
                role: '',
                phoneNumber: '',
                photo: { localUrl: '', remoteUrl: '' },
                isVerified: false,
                token: '',
                isAuthenticated: false,
                company: {},
            }
            dispatch({ type: 'UPDATE_ADMIN', payload: initialState })

        } catch (e) {
            alert(e)
        }
    }
}

