let initialState = {
    uid: '',
    id: '',
    firstname: '',
    lastname: '',
    phoneNumber: '',
    company: '',
    unit: '',
    role: '',
    photo: { localUrl: '', remoteUrl: '' },
    alertPhotos: { localUrl: [], remoteUrl: [] },
    document: { localUrl: '', remoteUrl: '' },
    category: '',
    subCategory: '',
    date: '',
    description: '',
    incidenceNumber: '',
    status: '',
    escalations: [],
    updates: [],
    alerts: [],
    stats: { open: 0, reviewed: 0, pending: 0, resolved: 0, closed: 0 },
    unseen: 0,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_ALERTS':
            return { ...state, alerts: action.payload }
        case 'UPDATE_ALERT_UNSEEN':
            return { ...state, unseen: action.payload }
        case 'UPDATE_ESCALATIONS':
            return { ...state, escalations: action.payload }
        case 'UPDATE_ALERT_UPDATES':
            return { ...state, updates: action.payload }
        case 'UPDATE_STATS':
            return { ...state, stats: action.payload }
        default:
            return state
    }
}