import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import C3Chart from 'react-c3js';
import 'c3/c3.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'

const colors = {
  pattern: ['#C34242', '#C9A749', '#49C962']
};

class AlertChart extends Component {

  static propTypes = {
    data: PropTypes.array
  }

  render() {
    return (
      <AUX>
        <C3Chart data={{
          type: 'donut',
          columns: [
            ['Open', this.props.data.filter(i => i.status === 'Open').length / this.props.data.length],
           
            ['Pending', this.props.data.filter(i =>  i.status === 'Pending').length / this.props.data.length],
           
            ['Closed', this.props.data.filter(i =>  i.status === 'Closed').length / this.props.data.length],
          ],

          onclick: function (d, i) { console.log("onclick", d, i); },
          onmouseover: function (d, i) { console.log("onmouseover", d, i); },
          onmouseout: function (d, i) { console.log("onmouseout", d, i); }
        }} color={colors} />
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {
    alerts: state.alert.alerts
  };
}

export default connect(mapStatetoProps)(AlertChart);   